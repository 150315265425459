<template>
  <div class="manage-release-job-info" >
    <div class="table-header">
      <div class="query-item">
        <span class="query-item-label">用户id: </span>
        <el-input class="query-item-input" v-model="queryParams.user_id" />
      </div>
      <div class="query-item">
        <span class="query-item-label">区划: </span>
        <el-cascader v-model="queryParams.regionModel" :options="regionTree"
                     :props="{ checkStrictly: true, value: 'region_code', label: 'region_name'}"
                     placeholder="选择城市"/>
      </div>
      <div class="query-item">
        <span class="query-item-label">审核状态: </span>
        <el-select  v-model="queryParams.examine_status" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="审核通过" :value="1"></el-option>
          <el-option label="审核不通过" :value="2"></el-option>
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">全职状态: </span>
        <el-select  v-model="queryParams.full_time_type" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option label="兼职" :value="0"></el-option>
          <el-option label="全职" :value="1"></el-option>
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">是否启用: </span>
        <el-select  v-model="queryParams.is_active" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </div>
      <div class="btn-group">
        <el-button type="primary" @click="searchBtn">查询</el-button>
        <el-button @click="resetBtn">重置</el-button>
      </div>
    </div>
    <div class="table-content custom-overflow">
      <el-table :data="pageData.rows" stripe style="width: 100%" show-overflow-tooltip >
        <el-table-column prop="user_id" label="用户id"  width="80" />
        <el-table-column prop="province_name" label="省份"  width="80" />
        <el-table-column prop="city_name" label="城市"  width="80" />
        <el-table-column prop="title" label="标题"   />
        <el-table-column prop="salary_enum" label="薪资情况" />
        <el-table-column prop="introduction" label="介绍" />
        <el-table-column prop="entry_time_enum" label="入场时间" />
        <el-table-column prop="end_time_enum" label="结束时间" />
        <el-table-column label="审核状态"  width="120">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span :class="{'success-row': scope.row.examine_status == 1, 'warning-row': scope.row.examine_status == 2, 'cancel-row': scope.row.examine_status == 3}">
                {{ scope.row.examine_status_enum }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="full_time_type_enum" label="全职类型" />
        <el-table-column label="是否启用" >
          <template #default="scope">
            <el-switch size="small" v-model="scope.row.is_active" disabled :active-value="1" :inactive-value="0" />
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" />
      </el-table>
      <div class="pagination">
        <div class="total">记录数: {{pageData.total}}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total" @current-change="currentChange" />
      </div>
    </div>
  </div>
</template>

<script>

import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import nodeServerRelease from '@/net/nodeServerRelease'

export default {
  name: 'ManageReleaseJobInfo',
  components: {

  },
  data () {
    return {
      dictInfo: {},
      regionTree: [],
      queryParams: {
        user_id: '',
        regionModel: [],
        province: '',
        city: '',
        examine_status: '',
        full_time_type: '',
        is_active: '',
      },

      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },

    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    async init () {
      //  初始化行政区划
      await this.initDictInfo();
      this.initRegionTree();

      this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {
        user_id: '',
        regionModel: [],
        province: '',
        city: '',
        examine_status: '',
        full_time_type: '',
        is_active: '',
      }
    },
    async initDictInfo(){
      let res = await nodeServer.regionInfo_getDictInfo();
      if(res.success && res.content){
        this.dictInfo = res.content;
      }
    },
    async initRegionTree(){
      let res = await nodeServer.regionInfo_getRegionTree({});
      if(res.success && res.content){
        this.regionTree = res.content;
      }
    },
    // 查询
    async searchBtn(){
      // 处理省份和城市
      // 处理区划信息
      if(this.queryParams.regionModel && this.queryParams.regionModel.length > 0){
        this.queryParams.province = this.queryParams.regionModel[0];
        this.queryParams.city = this.queryParams.regionModel[1] ? this.queryParams.regionModel[1] : '';
      }

      let searchParams = {
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        user_id: this.queryParams.user_id,
        province: this.queryParams.province,
        city: this.queryParams.city,
        examine_status: this.queryParams.examine_status,
        full_time_type: this.queryParams.full_time_type,
        is_active: this.queryParams.is_active,
      }
      let res = await nodeServerRelease.releaseJobInfo_getPageByAdmin(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          // 审核状态
          row.examine_status_enum = this.getDictNameByDictCode('release_examine_status', row.examine_status);
          // 全职状态
          row.full_time_type_enum = this.getDictNameByDictCode('full_time_type', row.full_time_type);
          // 薪资情况
          if(row.full_time_type == 0){
            row.salary_enum = '日薪' + row.salary + '元'
          } else {
            row.salary_enum = '年薪' + row.salary + '万及以上'
          }
          // 入场时间， 离场时间
          row.entry_time_enum = dateUtils.getDateString(new Date(row.entry_time));
          row.end_time_enum = dateUtils.getDateString(new Date(row.end_time));

        }

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
    getDictNameByDictCode(dictType, dictCode){
      if(this.dictInfo && this.dictInfo[dictType] && this.dictInfo[dictType][dictCode]){
        return this.dictInfo[dictType][dictCode];
      }
      return '';
    },
  }
}
</script>
<style lang="scss" scoped>
.manage-release-job-info{
  width: 100%;
  height: 100%;
  .table-header{
    height: 120px;
    line-height: 60px;
    display: flex;
    flex-wrap: wrap;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .table-content{
    height: calc(100% - 120px);
    overflow-y: auto;
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }
    .success-row{
      color: #00c48f;
    }
    .warning-row{
      color: #f44336;
    }
    .cancel-row{
      color: #ff9f00;
    }
  }
}
</style>
<style lang="scss">
  .order-info-dialog{
    width: 1200px !important;
    .el-dialog__body{
      padding: 0;
    }
  }
</style>
