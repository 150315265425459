<template>
  <div class="member-content">
    <div class="member-header">
      <div class="user-info">
        <i class="iconfont icon-yonghufill"></i>
        <span class="user-id-label">ID: </span>
        <span class="user-id">{{userInfo ? userInfo.id : ''}}</span>
        <span class="user-name-label">用户名: </span>
        <span class="user-name">{{userInfo ? userInfo.name : ''}}</span>
      </div>
      <div class="user-vip" :class="{'active': vipType==2, 'try-out': vipType==1}">
        <i class="iconfont icon-vip"></i>
        <span class="vip-message">{{vipMessage}}</span>
        <span class="vip-expire-time" v-if="vipType!=0">到期时间{{vipExpireTime}}</span>
        <span class="vip-expire-time" v-else >在execl工具中首次登录赠送10天会员试用</span>
      </div>
    </div>
    <div class="vip-list">
      <div v-for="(item,index) in vipItemList" class="vip-item" @click="clickVipItem(item)" :class="{'active': selectVipItem && item.code == (selectVipItem.code)}">
        <div class="vip-item-content">
          <div class="vip-item-name">{{item.name}}</div>
          <div class="vip-order-cost">￥<span>{{item.order_cost_yuan}}</span></div>
        </div>
        <div class="vip-item-footer">{{item.message}}</div>
      </div>
    </div>
    <div class="vip-pay-content" v-show="selectVipItem">
      <div class="qr-code-content">
        <div class="refresh-div" v-show="showQrCodeRefresh" @click="clickRefreshQrCode">
          <i class="iconfont icon-shuaxin"></i>
        </div>
        <div class="qrcode" ref="qrCodeUrl"></div>
      </div>
      <div class="text-content">
        <div class="tip-text">
          打开微信扫一扫，支付 {{selectVipItem ? selectVipItem.order_cost_yuan: 0}} 元
        </div>
        <!--<div class="qrcode-refresh">
          <i class="iconfont icon-shuaxin"></i>
          刷新二维码
        </div>-->
      </div>

      <div class="pay-success" v-show="showPaySuccess">
        <i class="iconfont icon-zhifuchenggong"></i>
        <span>支付成功, 会员时间已经更新</span>
      </div>
    </div>
  </div>
</template>

<script>

import {getCookie, setCookie} from "../net/cookies";
import {dateUtils} from "../utils/dateUtils";
import QRCode from 'qrcodejs2';
import nodeServer from '../net/nodeServer'
import { ElMessage } from 'element-plus'

export default {
  name: 'Member',
  components: {

  },
  data () {
    return {
      userInfo: {},
      vipType: 0,   //vip类型： 0未开通, 1试用, 2正式, 3试用到期, 4会员到期
      vipMessage: '',
      vipMessageMap: {0: '未开通', 1: '试用会员', 2: '正式会员', 3: '试用到期', 4: '会员到期'},
      vipExpireTime: '',    //vip到期时间
      selectVipItem: null,
      vipItemList: [],
      showQrCodeRefresh: false,
      showPaySuccess: false,
      memberInfo:{
        nativeCode: 'c8a20ed6c73f4c048fd8fa5234fec233',
        outTradeNo: 'c8a20ed6c73f4c048fd8fa5234fec233',
        timer: null,
        duration: 0
      },
    }
  },
  mounted () {
    this.getMemberInfoList();
    this.initUserInfo();
  },
  beforeUnmount () {
    // 清除定时器
    this.stopTimer();
  },
  methods: {
    initUserInfo () {
      // 获取用户信息
      try{
        let userInfoStr = getCookie("userInfo");
        let userInfo = JSON.parse(userInfoStr);
        this.userInfo = userInfo;
        let vipExpireTime = new Date(userInfo.vip_expire_time);
        vipExpireTime = dateUtils.getDateString(vipExpireTime);
        this.vipExpireTime = vipExpireTime;
        let todayTime = dateUtils.getTodayDateString();
        // 判断会员过期
        if(todayTime > vipExpireTime){
          if(userInfo.vip_type == 0){
            this.vipType = 0;
          } else if (userInfo.vip_type == 1){
            this.vipType = 3;
          } else {
            this.vipType = 4;
          }
        } else {
          this.vipType = userInfo.vip_type;
        }
        this.vipMessage = this.vipMessageMap[this.vipType];
      } catch (e) {

      }
    },

    async getUserInfo(){
      // 获取用户信息, 如果有的话, 通过token接口获取信息
      let token = getCookie("token");
      if(token){
        //重新请求获取用户信息
        let res = await nodeServer.userInfo_loginBytoken({token: token});
        if(res.success && res.content){
          let userInfo = {
            id: res.content.id,
            name: res.content.name,
            vip_expire_time: res.content.vip_expire_time,
            vip_type: res.content.vip_type,
            is_manager: res.content.is_manager,
            token: res.content.token,
          }
          this.userInfo = userInfo;
          setCookie("userInfo", JSON.stringify(userInfo), 30);
          setCookie("token", userInfo.token, 30);
        }else{
          // 获取登录信息失败，退出旧的登录，并且清理cookie
          this.logout();
        }
      }
      this.initUserInfo();
    },
    async getMemberInfoList(){
      let memberInfoPage = await nodeServer.memberInfo_getPage({});
      if(memberInfoPage && memberInfoPage.success && memberInfoPage.content){
        this.vipItemList = memberInfoPage.content.rows;
        // 处理价格
        for(let item of this.vipItemList){
          item['order_cost_yuan'] = item.order_cost / 100;
        }
      }
    },
    async clickVipItem(item){
      this.selectVipItem = item;
      // 支付成功的，不切换二维码
      if(this.showPaySuccess){
        return;
      }
      // 获取支付二维码
      let result = await nodeServer.weixinPay_createNativeCode({code: item.code});
      if(result && result.success && result.content){
        this.memberInfo = result.content;

        // 生成对应的二维码
        this.creatQrCode();
        // 重新开始定时
        this.startTimer();
      }
    },
    creatQrCode() {
      let qrCodeUrl = this.$refs.qrCodeUrl;
      // 清空内容
      qrCodeUrl.innerHTML = ''
      let qrcode = new QRCode(qrCodeUrl, {
        text: this.memberInfo.nativeCode, // 需要转换为二维码的内容
        colorDark: '#000000',
        colorLight: '#ffffff',
        width: 120,
        height: 120,
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    startTimer() {
      this.stopTimer();
      this.memberInfo.timer = setInterval(async () => {
        this.memberInfo.duration++;
        // 调用接口获取是否完成了扫码支付，完成支付的，刷新数据
        let result = await nodeServer.orderInfo_getOneByOutTradeNo({outTradeNo: this.memberInfo.outTradeNo});
        if(result && result.success && result.content){
          let orderInfo = result.content;
          if(orderInfo.pay_status == 'SUCCESS'){
            //  支付成功
            this.showPaySuccess = true;
            ElMessage.success('支付成功')

            // 重新获取用户信息
            await this.getUserInfo();

            this.stopTimer();
          } else if(orderInfo.pay_status == 'CLOSED'){
            // 订单已经被关闭
            this.showQrCodeRefresh = true;
            ElMessage('订单已经关闭')
            this.stopTimer();
          }
        }

        // 判断是否超过了5分钟，5分钟则显示刷新按钮
        if(this.memberInfo.duration > 2 * 60){
          this.showQrCodeRefresh = true;
          this.stopTimer();
        }
      }, 2500)
    },
    stopTimer() {
      if(this.memberInfo.timer){
        clearInterval(this.memberInfo.timer)
      }
      this.memberInfo.timer = null;
      this.memberInfo.duration = 0;
    },
    // 刷新二维码
    clickRefreshQrCode(){
      this.showQrCodeRefresh = false;
      this.clickVipItem(this.selectVipItem);
    }
  }
}
</script>
<style lang="scss" scoped>
.member-content{
  .member-header{
    margin-top: 12px;
    font-size: 20px;
    span{
      color: #000;
    }
    .iconfont{
      font-size: 20px;
    }
    .user-info{
      margin-left: 12px;
      span{
        font-size: 14px;
        color: #8c8c8c;
        font-weight: bold;
        margin-left: 4px;
      }
      .user-name-label{
        margin-left: 24px;
      }
    }
    .user-vip{
      margin: 12px;
      .vip-message{
        font-size: 14px;
        color: #8c8c8c;
        margin-left: 4px;
      }
      .vip-expire-time{
        font-size: 14px;
        color: #8c8c8c;
        margin-left: 12px;
        /*display: none;*/
      }
      .icon-vip{
        color: #8c8c8c;
      }

      &.active,&.try-out{
        .vip-message{
          color: #d4b106;
        }
        .vip-expire-time{
          display: initial;
        }
        .icon-vip{
          color: #d4b106;
        }
      }
    }
  }
  .vip-list{
    width: calc(100% - 24px);
    height: 140px;
    margin: 24px 12px;
    display: flex;
    .vip-item{
      width: 240px;
      height: 100%;
      background: rgba(191, 191, 191, 0.1);
      border: #d9d9d9 solid 3px;
      border-radius: 15px;
      cursor: pointer;
      &:first-child{
        margin-right: 40px;
      }
      &.active{
        background: rgba(50, 166, 134, 0.1);
        border: #32A686 solid 3px;
        .vip-item-footer{
          background: #32A686;
          color: #fff;
        }
      }
      .vip-item-content{
        width: 100%;
        height: 104px;
        color: #000;
        padding-top: 24px;
        .vip-item-name{
          text-align: center;
          font-size: 18px;
          font-weight: bold;
        }
        .vip-order-cost{
          text-align: center;
          font-size: 18px;
          margin-top: 16px;
          span{
            font-weight: bold;
            font-size: 32px;
          }
        }
      }
      .vip-item-footer{
        width: 100%;
        height: 32px;
        background: #d9d9d9;
        border-radius: 0 0 12px 12px;
        line-height: 30px;
        font-size: 14px;
        text-align: center;
        color: #000;
      }
    }
  }
  .vip-pay-content{
    width: calc(100% - 24px);
    height: 160px;
    margin: 24px 12px;
    background: rgba(50, 166, 134, 0.1);
    border-radius: 12px 12px;
    display: flex;
    position: relative;
    .qr-code-content{
      height: 120px;
      width: 120px;
      margin: 20px;
      margin-left: 64px;
      position: relative;
      .refresh-div{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.9);
        text-align: center;
        cursor: pointer;
        &:hover{
          .iconfont{
            color: rgba(50, 166, 134, 0.7);
          }
        }
        .iconfont{
          font-size: 40px;
          color: rgba(50, 166, 134, 0.9);
          line-height: 120px;
        }
      }
    }
    .text-content{
      margin: auto 12px;
      .tip-text{
        margin: 24px 0;
      }
      .qrcode-refresh{
        color: #32A686;
        font-size: 12px;
        cursor: pointer;
        &:hover{
          color: rgba(50, 166, 134, 0.8);
        }
        i{
          font-size: 10px;
        }

      }
    }

    .pay-success{
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.9);
      text-align: center;
      line-height: 160px;
      .iconfont{
        color: #32A686;
        font-size: 40px;
        margin: 0 16px;
      }
      span{
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
</style>
<style lang="scss">
  .member-content{
    .qr-code-content{
      .qrcode{
        height: 100%;
        width: 100%;
        canvas{
          display: none !important;
        }
        img{
          height: 100%;
          width: 100%;
        }
      }
    }
  }
</style>
