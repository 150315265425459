let { v4 } = require('uuid');

class defaultUtils {
    constructor() {

    }

    getUuidString(){
        let uuid = v4();
        let uuidString = uuid.toString();
        uuidString = uuidString.replace(/-/g, '');       // 去掉-字符，使用空格代替
        return uuidString;
    }
}

module.exports = {
    defaultUtils: new defaultUtils()
}

