let defaultData = {
}

defaultData.menuList = [
  {name: "审计助手插件", code: "execlAddIn", routeName: "Main"},
  {name: "信息发布", code: "ReleaseInfo", routeName: "ReleaseInfo"},
  // {name: "审计小哥课程", code: "course", routeName: "Course"},
  {name: "后台管理", code: "ManageModule", routeName: "ManageModule", isManager: true},
]

export default defaultData;
