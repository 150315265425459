<template>
  <div class="manage-release-audit-info" >
    <div class="table-header">
      <div class="query-item">
        <span class="query-item-label">用户id: </span>
        <el-input class="query-item-input" v-model="queryParams.user_id" />
      </div>
      <div class="query-item">
        <span class="query-item-label">业务类型: </span>
        <el-select  v-model="queryParams.business_type" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option label="基础信息" :value="1"></el-option>
          <el-option label="个人履历" :value="2"></el-option>
          <el-option label="发布工作" :value="3"></el-option>
          <el-option label="评价信息" :value="4"></el-option>
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">业务操作类型: </span>
        <el-select  v-model="queryParams.examine_type" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option label="新增数据" :value="1"></el-option>
          <el-option label="修改数据" :value="2"></el-option>
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">业务id: </span>
        <el-input class="query-item-input" v-model="queryParams.business_id" />
      </div>
      <div class="query-item">
        <span class="query-item-label">审核状态: </span>
        <el-select  v-model="queryParams.examine_status" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="审核通过" :value="1"></el-option>
          <el-option label="审核不通过" :value="2"></el-option>
          <el-option label="取消审核" :value="3"></el-option>
        </el-select>
      </div>
      <div class="btn-group">
        <el-button type="primary" @click="searchBtn">查询</el-button>
        <el-button @click="resetBtn">重置</el-button>
      </div>
    </div>
    <div class="table-content custom-overflow">
      <el-table :data="pageData.rows" stripe style="width: 100%" show-overflow-tooltip >
        <el-table-column prop="id" label="id" width="60" />
        <el-table-column prop="user_id" label="用户id" width="80" />
        <el-table-column prop="business_type_enum" label="业务类型"  width="120"  />
        <el-table-column prop="examine_type_enum" label="业务操作类型" width="120" />
        <el-table-column prop="business_id" label="业务id" width="80"  />
        <el-table-column label="审核状态"  width="120">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span :class="{'success-row': scope.row.examine_status == 1, 'warning-row': scope.row.examine_status == 2, 'cancel-row': scope.row.examine_status == 3}">
                {{ scope.row.examine_status_enum }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="examine_message" label="审核意见" />
        <el-table-column prop="examine_user_name" label="审核人"  width="80" />
        <el-table-column prop="create_time" label="创建时间" />
        <el-table-column prop="examine_time" label="审核时间" />
        <el-table-column label="操作">
          <template #default="scope" >
            <el-button size="small" type="primary" @click="showAuditDialogInfo(scope.$index, scope.row)" v-if="scope.row && scope.row.examine_status == 0">
              审核
            </el-button>
            <el-button size="small" @click="showAuditDialogInfo(scope.$index, scope.row, true)" v-else>
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="total">记录数: {{pageData.total}}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total" @current-change="currentChange" />
      </div>
    </div>
  </div>

  <el-dialog
    v-model="openAuditDialogFlag"
    title="审核信息"
    class="edit-info-dialog"
    :close-on-click-modal="false"
  >
    <div class="edit-info-dialog-content">
      <el-form :model="auditDialogInfo" label-width="auto" :rules="auditRules" @submit.native.prevent ref="auditInfoFormRef" >
        <!--        基础信息-->
        <div class="info-group" v-if="auditDialogInfo.business_type == 1">
          <div class="info-group-title">基础信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="联系人" prop="name">
              <el-input class="info-item-input" v-model="auditDialogInfo.businessEntity.name" disabled />
            </el-form-item>
            <el-form-item class="info-item" label="性别" prop="sex">
              <el-radio-group v-model="auditDialogInfo.businessEntity.sex" class="info-item-input" disabled>
                <el-radio value="1">男</el-radio>
                <el-radio value="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="微信号" prop="wechat">
              <el-input class="info-item-input" v-model="auditDialogInfo.businessEntity.wechat" disabled />
            </el-form-item>
            <el-form-item class="info-item" label="手机号" prop="phone_number">
              <el-input class="info-item-input" v-model="auditDialogInfo.businessEntity.phone_number" disabled />
            </el-form-item>
          </div>
        </div>
        <!--        个人履历-->
        <div class="info-group"  v-else-if="(auditDialogInfo.business_type == 2) || (auditDialogInfo.evaluateEntity && auditDialogInfo.evaluateEntity.business_type == 2)">
          <div class="info-group-title">个人履历</div>
          <div class="info-row">
            <el-form-item class="info-item" label="工作地点" prop="regionModel">
              <el-input class="info-item-input" v-model="auditDialogInfo.businessEntity.region_name" disabled />
            </el-form-item>
            <el-form-item class="info-item" label="求职状态" prop="fullTimeTypeMode">
              <el-select  v-model="auditDialogInfo.businessEntity.fullTimeTypeMode" class="info-item-input" disabled>
                <el-option label="启用-寻找兼职" :value="0"></el-option>
                <el-option label="启用-寻找全职" :value="1"></el-option>
                <el-option label="关闭-暂不考虑" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="学校" prop="schoolTags">
              <el-tag
                v-for="tag in auditDialogInfo.businessEntity.schoolTags"
                :key="tag"
                :disable-transitions="false"
                size="large">
                {{ tag }}
              </el-tag>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="证书" prop="certificateTags">
              <el-tag
                v-for="tag in auditDialogInfo.businessEntity.certificateTags"
                :key="tag"
                :disable-transitions="false"
                size="large">
                {{ tag }}
              </el-tag>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="个人介绍" prop="introduction">
              <el-input class="info-item-input" v-model="auditDialogInfo.businessEntity.introduction" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }" disabled />
            </el-form-item>
          </div>
        </div>
        <!--        发布工作-->
        <div class="info-group" v-else-if="(auditDialogInfo.business_type == 3) || (auditDialogInfo.evaluateEntity && auditDialogInfo.evaluateEntity.business_type == 3)">
          <div class="info-group-title">发布工作</div>
          <div class="info-row">
            <el-form-item class="info-item" label="标题" prop="title">
              <el-input class="info-item-input" v-model="auditDialogInfo.businessEntity.title" disabled />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="工作地点" prop="regionModel">
              <el-input class="info-item-input" v-model="auditDialogInfo.businessEntity.region_name" disabled />
            </el-form-item>
            <el-form-item class="info-item" label="工作类型" prop="sex">
              <el-select  v-model="auditDialogInfo.businessEntity.full_time_type" class="info-item-input" disabled >
                <el-option label="兼职" :value="0"></el-option>
                <el-option label="全职" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="工作介绍" prop="introduction">
              <el-input class="info-item-input" v-model="auditDialogInfo.businessEntity.introduction" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }" disabled />
            </el-form-item>
          </div>
          <div class="info-row" v-if="auditDialogInfo.businessEntity.full_time_type == 0">
            <el-form-item class="info-item" label="预计入场时间" prop="entry_time">
              <el-date-picker v-model="auditDialogInfo.businessEntity.entry_time" type="date" placeholder="选择入场时间" format="YYYY-MM-DD" value-format="YYYY-MM-DD" disabled/>
            </el-form-item>
            <el-form-item class="info-item" label="预计结束时间" prop="end_time">
              <el-date-picker v-model="auditDialogInfo.businessEntity.end_time" type="date" placeholder="选择结束时间" format="YYYY-MM-DD" value-format="YYYY-MM-DD" disabled/>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="薪资情况" prop="salary">
              <div  v-if="auditDialogInfo.businessEntity.full_time_type == 0">
                日薪：
                <el-input-number class="info-item-input" v-model="auditDialogInfo.businessEntity.salary"  :min="0" :max="10000"  controls-position="right" disabled/>
                元
              </div>
              <div v-else>
                年薪：
                <el-input-number class="info-item-input" v-model="auditDialogInfo.businessEntity.salary"  :min="0" :max="100" controls-position="right" disabled/>
                万元及以上
              </div>
            </el-form-item>
          </div>
        </div>
        <!--        评论信息-->
        <div class="info-group" v-if="auditDialogInfo.business_type == 4">
          <div class="info-group-title">评论信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="评论内容" prop="evaluate_message">
              <el-input class="info-item-input" v-model="auditDialogInfo.evaluateEntity.evaluate_message" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }" disabled />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="照片" prop="file_group_id">
              <div class="info-item-images">
                <template v-for="(item, index) in auditDialogInfo.evaluateEntity.imageUrlList" :key="index" >
                  <el-image
                    :src="item"
                    :preview-src-list="auditDialogInfo.evaluateEntity.imageUrlList"
                    fit="cover"
                    hide-on-click-modal
                    :initial-index="index"
                  />
                </template>
              </div>
            </el-form-item>
          </div>
        </div>
        <!--        审核信息-->
        <div class="info-group">
          <div class="info-group-title">审核信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="审核状态" prop="examine_status">
              <el-select  v-model="auditDialogInfo.examine_status" class="info-item-input" :disabled="this.auditDialogOnlyShow">
                <el-option label="待审核" :value="0" disabled></el-option>
                <el-option label="审核通过" :value="1"></el-option>
                <el-option label="审核不通过" :value="2"></el-option>
                <el-option label="取消审核" :value="3" disabled></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="审核意见" prop="examine_message">
              <el-input class="info-item-input" v-model="auditDialogInfo.examine_message" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }" :disabled="this.auditDialogOnlyShow" />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="审核人" prop="examine_user_name">
              <el-input class="info-item-input" v-model="auditDialogInfo.examine_user_name" disabled />
            </el-form-item>
            <el-form-item class="info-item" label="审核时间" prop="examine_time">
              <el-input class="info-item-input" v-model="auditDialogInfo.examine_time" disabled />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="btn-group">
        <el-button type="primary" @click="saveAuditInfoBtn" >保存</el-button>
      </div>
    </div>
  </el-dialog>

</template>

<script>

import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import nodeServerRelease from '@/net/nodeServerRelease'
import { ElMessage } from 'element-plus'
import defaultConfig from '@/config/defaultConfig'

export default {
  name: 'ManageReleaseAuditInfo',
  components: {

  },
  data () {
    return {
      dictInfo: {},
      regionTree: [],

      pageNum: 1, pageSize: 10,

      queryParams: {
        user_id: '',
        business_type: '',
        examine_type: '',
        business_id: '',
        examine_status: '',
      },

      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },

      auditDialogInfo: {},
      openAuditDialogFlag: false,
      auditDialogOnlyShow: false,
      auditRules: {
        examine_status: [
          { required: true, message: '请选择审核状态', trigger: 'change' }
        ],
        examine_message: [
          { required: true, message: '请输入审核意见', trigger: 'blur' },
          { max: 100, message: '最大长度100', trigger: 'blur' },
        ],
      },
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    async init () {
      //  初始化行政区划
      await this.initDictInfo();
      this.initRegionTree();

      this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {
        user_id: '',
        business_type: '',
        examine_type: '',
        business_id: '',
        examine_status: '',
      }

    },
    async initDictInfo(){
      let res = await nodeServer.regionInfo_getDictInfo();
      if(res.success && res.content){
        this.dictInfo = res.content;
      }
    },
    async initRegionTree(){
      let res = await nodeServer.regionInfo_getRegionTree({});
      if(res.success && res.content){
        this.regionTree = res.content;
      }
    },
    // 查询
    async searchBtn(){
      let searchParams = {
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        user_id: this.queryParams.user_id,
        business_type: this.queryParams.business_type,
        examine_type: this.queryParams.examine_type,
        business_id: this.queryParams.business_id,
        examine_status: this.queryParams.examine_status,
      }
      let res = await nodeServerRelease.releaseAuditInfo_getPage(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          // 审核状态
          row.examine_status_enum = this.getDictNameByDictCode('release_examine_status', row.examine_status);
          // 业务类型
          row.business_type_enum = this.getDictNameByDictCode('release_business_type', row.business_type);
          // 业务操作类型
          row.examine_type_enum = this.getDictNameByDictCode('release_examine_type', row.examine_type);
        }

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
    getDictNameByDictCode(dictType, dictCode){
      if(this.dictInfo && this.dictInfo[dictType] && this.dictInfo[dictType][dictCode]){
        return this.dictInfo[dictType][dictCode];
      }
      return '';
    },
    async showAuditDialogInfo(index, row, onlyShow){
      this.auditDialogOnlyShow = onlyShow;
      this.auditDialogInfo = JSON.parse(JSON.stringify(row));
      if(!onlyShow){
        // 如果是需要审核的，初始化审核内容(默认通过)
        this.auditDialogInfo.examine_status = 1;
        this.auditDialogInfo.examine_message = '';
      }
      // 处理business_json字符串转换
      this.auditDialogInfo.businessEntity = JSON.parse(this.auditDialogInfo.business_json);

      // 处理评论信息数据
      if(this.auditDialogInfo.business_type == 4){
        this.auditDialogInfo.evaluateEntity = JSON.parse(this.auditDialogInfo.business_json);
        this.auditDialogInfo.businessEntity = {};
        // 接口获取对应业务 个人简历/工作信息 的数据
        let res = null;
        if(this.auditDialogInfo.evaluateEntity.business_type == 2){ // 个人履历
          res = await nodeServerRelease.releaseStaffInfo_getPageByAdmin({pageNum: 1, pageSize: 1, id: this.auditDialogInfo.evaluateEntity.business_id})
        } else if(this.auditDialogInfo.evaluateEntity.business_type == 3) { // 工作信息
          res = await nodeServerRelease.releaseJobInfo_getPageByAdmin({pageNum: 1, pageSize: 1, id: this.auditDialogInfo.evaluateEntity.business_id})
        }
        if(res && res.content && res.content.rows && res.content.rows.length > 0 ) {
          this.auditDialogInfo.businessEntity = res.content.rows[0];
        }
        // 接口获取评论的图片列表
        let evaluateRes = await nodeServerRelease.releaseEvaluateInfo_getPageByAdmin({pageNum: 1, pageSize: 1, id: this.auditDialogInfo.business_id})
        if(evaluateRes && evaluateRes.content && evaluateRes.content.rows && evaluateRes.content.rows.length > 0 ) {
          let evaluateItem = evaluateRes.content.rows[0];
          // 处理附件列表
          if(evaluateItem.fileList && evaluateItem.fileList.length > 0){
            evaluateItem.imageUrl = '';
            evaluateItem.imageUrlList = [];
            for(let fileItem of evaluateItem.fileList){
              let imageUrl = defaultConfig.server_file_root + fileItem.file_path;
              evaluateItem.imageUrlList.push(imageUrl);
            }
            this.auditDialogInfo.evaluateEntity.imageUrl = evaluateItem.imageUrlList[0];
            this.auditDialogInfo.evaluateEntity.imageUrlList = evaluateItem.imageUrlList;
          }
        }
      }


      // 处理个人履历里面的特殊字段
      if(this.auditDialogInfo.business_type == 2 || (this.auditDialogInfo.evaluateEntity && this.auditDialogInfo.evaluateEntity.business_type == 2)){
        // 省份、城市
        this.translateRegionCode(this.auditDialogInfo.businessEntity);
        // 学校、证书
        if(this.auditDialogInfo.businessEntity.school){
          this.auditDialogInfo.businessEntity.schoolTags = this.auditDialogInfo.businessEntity.school.split(',');
        }else{
          this.auditDialogInfo.businessEntity.schoolTags = [];
        }
        if(this.auditDialogInfo.businessEntity.certificate){
          this.auditDialogInfo.businessEntity.certificateTags = this.auditDialogInfo.businessEntity.certificate.split(',');
        }else{
          this.auditDialogInfo.businessEntity.certificateTags = [];
        }
        // 求职状态
        this.auditDialogInfo.businessEntity.fullTimeTypeMode = this.auditDialogInfo.businessEntity.full_time_type;
        if(this.auditDialogInfo.businessEntity.is_active == 0){
          this.auditDialogInfo.businessEntity.fullTimeTypeMode = 2;
        }
      } else if(this.auditDialogInfo.business_type == 3  || (this.auditDialogInfo.evaluateEntity && this.auditDialogInfo.evaluateEntity.business_type == 3)){
        // 处理发布工作里面的特殊字段
        // 省份、城市
        this.translateRegionCode(this.auditDialogInfo.businessEntity);
      }

      console.log(this.auditDialogInfo);
      // 打开审核弹窗
      this.openAuditDialogFlag = true;
    },
    // 处理省份和城市的字段翻译
    translateRegionCode(item){
      if(item && item.province){
        // 遍历获取省份
        for(let regionItem of this.regionTree){
          if(item.province == regionItem.region_code){
            item.province_name = regionItem.region_name;
            // 遍历获取城市
            if(item.city && regionItem.children){
              for(let cityItem of regionItem.children){
                if(item.city == cityItem.region_code) {
                  item.city_name = cityItem.region_name;
                  break;
                }
              }
            }
            break;
          }
        }
        item.region_name = item.province_name;
        if(item.city_name){
          item.region_name += ' - ' + item.city_name;
        }
      }
    },
    // 保存审核状态
    async saveAuditInfoBtn(){
      // 保存数据到服务器
      const myComponentInstance = this.$refs.auditInfoFormRef;
      if (!myComponentInstance) return
      await myComponentInstance.validate((valid, fields) => {
        if (valid) {
          this.saveAuditInfoToService();
        } else {
          ElMessage.error('请先完成信息填写');
        }
      })
    },
    async saveAuditInfoToService(){
      let entityInfo = JSON.parse(JSON.stringify(this.auditDialogInfo));
      console.log(entityInfo)
      let res = await nodeServerRelease.releaseAuditInfo_auditOneById({
        id: entityInfo.id,
        examine_status: entityInfo.examine_status,
        examine_message: entityInfo.examine_message,
      });
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        ElMessage.success('审核成功');
        // 关闭弹窗
        this.openAuditDialogFlag = false;
        await this.searchBtn();
      } else {
        ElMessage.error('审核失败，' + res.message);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.manage-release-audit-info{
  width: 100%;
  height: 100%;
  .table-header{
    height: 120px;
    line-height: 60px;
    display: flex;
    flex-wrap: wrap;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .table-content{
    height: calc(100% - 120px);
    overflow-y: auto;
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }
    .success-row{
      color: #00c48f;
    }
    .warning-row{
      color: #f44336;
    }
    .cancel-row{
      color: #ff9f00;
    }
  }
}

//弹窗
.edit-info-dialog-content{
  padding: 0 20px 24px 20px;
  .info-group{
    .info-group-title{
      height: 24px;
      line-height: 22px;
      font-size: 14px;
      border-bottom: 1px solid #8c8c8c;
      margin-bottom: 16px;
    }
    .info-row{
      display: flex;
      .info-item{
        flex: 1;
        margin-right: 32px;
        .tag-input, .keyword-button-new-tag{
          width: 160px !important;
        }
        .el-tag{
          margin-right: 8px;
          margin-bottom: 4px;
        }
        .tip-text{
          font-size: 12px;
          margin-left: 12px;
          color: #8c8c8c;
        }
        .info-item-images{
          display: flex;
          .el-image{
            width: 160px;
            height: 120px;
            margin-right: 24px;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .btn-group{
    text-align: center;
  }
}
</style>
<style lang="scss">
.edit-info-dialog{
  width: 800px !important;
  .el-dialog__body{
    padding: 0;
  }
}

</style>
