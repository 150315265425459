import axiosWraper from './axiosWraper'

const proxyUrl = '/node_server'

// 消息发布
const nodeServerRelease = {

  // 基本信息
  /**
   * 获取消息发布-基本信息数据
   * @param params
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   * }
   */
  releaseBaseInfo_getPage: async params => {
    const url = `${proxyUrl}/releaseBaseInfo/getPage`
    return axiosWraper.getData(url, params)
  },

  /**
   * 创建消息发布-基本信息
   * @param params
   * {
   *    "user_id": 11212,
   *    "name": "李丹阳",
   *    "phone_number": "17512081250",
   *    "wechat": "itsegg_",
   *    "sex": "1",     //性别, 1男, 2女
   * ｝
   */
  releaseBaseInfo_create: async params => {
    const url = `${proxyUrl}/releaseBaseInfo/create`
    return axiosWraper.post(url, params)
  },

  /**
   * 更新消息发布-基本信息
   * @param params
   * {
   *    "id": 11212,
   *    "user_id": 11212,
   *    "name": "李丹阳",
   *    "phone_number": "17512081250",
   *    "wechat": "itsegg_",
   *    "sex": "1",     //性别, 1男, 2女
   * ｝
   */
  releaseBaseInfo_update: async params => {
    const url = `${proxyUrl}/releaseBaseInfo/update`
    return axiosWraper.post(url, params)
  },

  /**
   * 获取自己的消息发布-基本信息
   * @param params
   * {
   * }
   */
  releaseBaseInfo_getOneBySelf: async params => {
    const url = `${proxyUrl}/releaseBaseInfo/getOneBySelf`
    return axiosWraper.getData(url, params)
  },

  // 工作信息
  /**
   * 获取消息发布-工作信息数据(用户列表，只看已审核通过的内容)
   * @param params
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      full_time_type: 全职类型(0: 兼职, 1: 全职),
   *      province: 省份,
   *      city: 城市,
   *      is_active: 是否启用,
   * }
   */
  releaseJobInfo_getPage: async params => {
    const url = `${proxyUrl}/releaseJobInfo/getPage`
    return axiosWraper.getData(url, params)
  },

  /**
   * 获取消息发布-工作信息数据(自己列表，只看自己的内容)
   * @param params
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      full_time_type: 全职类型(0: 兼职, 1: 全职),
   *      province: 省份,
   *      city: 城市,
   *      is_active: 是否启用,
   * }
   */
  releaseJobInfo_getPageBySelf: async params => {
    const url = `${proxyUrl}/releaseJobInfo/getPageBySelf`
    return axiosWraper.getData(url, params)
  },

  /**
   * 获取消息发布-工作信息数据(管理员列表)
   * @param params
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      full_time_type: 全职类型(0: 兼职, 1: 全职),
   *      province: 省份,
   *      city: 城市,
   *      is_active: 是否启用,
   * }
   */
  releaseJobInfo_getPageByAdmin: async params => {
    const url = `${proxyUrl}/releaseJobInfo/getPageByAdmin`
    return axiosWraper.getData(url, params)
  },

  /**
   * 创建消息发布-工作信息
   * @param params
   * {
   *    "user_id": 11212,
   *    "is_active": 1,  //是否启用
   *    "province": "省份",
   *    "city": "城市",
   *    "title": "标题",
   *    "keyword": "关键词(逗号,隔开)",
   *    "introduction": "介绍",
   *    full_time_type: 全职类型(0: 兼职, 1: 全职),
   *    "entry_time": "2024-10-30",   //预计入场时间
   *    "end_time": "2024-10-30",   //预计结束时间
   *    "salary": "",   //薪资情况
   * ｝
   */
  releaseJobInfo_create: async params => {
    const url = `${proxyUrl}/releaseJobInfo/create`
    return axiosWraper.post(url, params)
  },

  /**
   * 更新消息发布-工作信息
   * @param params
   * {
   *    "id": 11212,
   *    "user_id": 11212,
   *    "is_active": 1,  //是否启用
   *    "province": "省份",
   *    "city": "城市",
   *    "title": "标题",
   *    "keyword": "关键词(逗号,隔开)",
   *    "introduction": "介绍",
   *    full_time_type: 全职类型(0: 兼职, 1: 全职),
   *    "entry_time": "2024-10-30",   //预计入场时间
   *    "end_time": "2024-10-30",   //预计结束时间
   *    "salary": "",   //薪资情况
   * ｝
   */
  releaseJobInfo_update: async params => {
    const url = `${proxyUrl}/releaseJobInfo/update`
    return axiosWraper.post(url, params)
  },

  /**
   * 删除消息发布-工作信息
   * @param params
   * {
   *    "id": 11212,
   * ｝
   */
  releaseJobInfo_deleteById: async params => {
    const url = `${proxyUrl}/releaseJobInfo/deleteById`
    return axiosWraper.post(url, params)
  },

  // 人员信息
  /**
   * 获取消息发布-人员信息数据(用户列表，只看已审核通过的内容)
   * @param params
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      full_time_type: 全职类型(0: 兼职, 1: 全职),
   *      province: 省份,
   *      city: 城市,
   *      is_active: 是否启用,
   * }
   */
  releaseStaffInfo_getPage: async params => {
    const url = `${proxyUrl}/releaseStaffInfo/getPage`
    return axiosWraper.getData(url, params)
  },

  /**
   * 获取消息发布-人员信息数据(用户列表，只看已审核通过的内容)
   * @param params
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      full_time_type: 全职类型(0: 兼职, 1: 全职),
   *      province: 省份,
   *      city: 城市,
   *      is_active: 是否启用,
   * }
   */
  releaseStaffInfo_getPageByAdmin: async params => {
    const url = `${proxyUrl}/releaseStaffInfo/getPageByAdmin`
    return axiosWraper.getData(url, params)
  },

  /**
   * 创建消息发布-人员信息
   * @param params
   * {
   *    "user_id": 11212,
   *    "is_active": 1,  //是否启用
   *    "school": "学校(逗号,隔开)",
   *    "certificate": "证书(逗号,隔开)",
   *    "province": "省份",
   *    "city": "城市",
   *    "title": "标题",
   *    "introduction": "介绍",
   *      full_time_type: 全职类型(0: 兼职, 1: 全职),
   * ｝
   */
  releaseStaffInfo_create: async params => {
    const url = `${proxyUrl}/releaseStaffInfo/create`
    return axiosWraper.post(url, params)
  },

  /**
   * 更新消息发布-人员信息
   * @param params
   * {
   *    "id": 11212,
   *    "user_id": 11212,
   *    "is_active": 1,  //是否启用
   *    "school": "学校(逗号,隔开)",
   *    "certificate": "证书(逗号,隔开)",
   *    "province": "省份",
   *    "city": "城市",
   *    "title": "标题",
   *    "introduction": "介绍",
   *      full_time_type: 全职类型(0: 兼职, 1: 全职),
   * ｝
   */
  releaseStaffInfo_update: async params => {
    const url = `${proxyUrl}/releaseStaffInfo/update`
    return axiosWraper.post(url, params)
  },
  /**
   * 更新消息发布-人员信息
   * @param params
   * {
   *    "id": 11212,
   *    "is_active": 1,  //是否启用
   *     full_time_type: 全职类型(0: 兼职, 1: 全职),
   * ｝
   */
  releaseStaffInfo_updateFullTime: async params => {
    const url = `${proxyUrl}/releaseStaffInfo/updateFullTime`
    return axiosWraper.post(url, params)
  },

  /**
   * 获取自己的消息发布-人员信息
   * @param params
   * {
   * }
   */
  releaseStaffInfo_getOneBySelf: async params => {
    const url = `${proxyUrl}/releaseStaffInfo/getOneBySelf`
    return axiosWraper.getData(url, params)
  },

  // 审核信息
  /**
   * 获取消息发布-审核信息数据(用户列表，只看已审核通过的内容)
   * @param params
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      business_id: 业务表id,
   *      user_id: 申请人id,
   *      examine_user_id: 审批人id,
   *      business_type: 业务类型, 1: 个人基础信息表, 2: 找工作人员信息表, 3: 工作信息表,
   *      examine_type: 审核类型, 1: 新增数据, 2: 修改数据
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      orderby_examine_time: 为true是按审核时间排序,
   * }
   */
  releaseAuditInfo_getPage: async params => {
    const url = `${proxyUrl}/releaseAuditInfo/getPage`
    return axiosWraper.getData(url, params)
  },

  /**
   * 获取消息发布-审核信息数据(用户列表，只看已审核通过的内容)
   * @param params
   * {
   *      business_id: 业务表id,
   *      business_type: 业务类型, 1: 个人基础信息表, 2: 找工作人员信息表, 3: 工作信息表,
   * }
   */
  releaseAuditInfo_getLastAuditBySelf: async params => {
    const url = `${proxyUrl}/releaseAuditInfo/getLastAuditBySelf`
    return axiosWraper.getData(url, params)
  },
  /**
   * 消息发布-根据id审核记录
   * @param params
   * {
   *      id: ID,
   *      examine_status: 1通过/2不通过,
   *      examine_message: "审核信息"
   * }
   */
  releaseAuditInfo_auditOneById: async params => {
    const url = `${proxyUrl}/releaseAuditInfo/auditOneById`
    return axiosWraper.post(url, params)
  },


  /**
   * 获取消息发布-评价信息数据(用户列表，只看已审核通过的内容)
   * @param params
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      evaluate_user_id: 被评价用户id,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      business_id: 业务数据id,
   *      business_type: 业务类型, 1: 个人基础信息表, 2: 找工作人员信息表, 3: 工作信息表,
   * ｝
   */
  releaseEvaluateInfo_getPage: async params => {
    const url = `${proxyUrl}/releaseEvaluateInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  /**
   * 获取消息发布-评价信息数据(自己列表，只看自己的内容)
   * @param params
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      evaluate_user_id: 被评价用户id,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      business_id: 业务数据id,
   *      business_type: 业务类型, 1: 个人基础信息表, 2: 找工作人员信息表, 3: 工作信息表,
   * ｝
   */
  releaseEvaluateInfo_getPageBySelf: async params => {
    const url = `${proxyUrl}/releaseEvaluateInfo/getPageBySelf`
    return axiosWraper.getData(url, params)
  },
  /**
   * 获取消息发布-评价信息数据(管理员列表)
   * @param params
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      evaluate_user_id: 被评价用户id,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      business_id: 业务数据id,
   *      business_type: 业务类型, 1: 个人基础信息表, 2: 找工作人员信息表, 3: 工作信息表,
   * ｝
   */
  releaseEvaluateInfo_getPageByAdmin: async params => {
    const url = `${proxyUrl}/releaseEvaluateInfo/getPageByAdmin`
    return axiosWraper.getData(url, params)
  },
  /**
   * 创建消息发布-评价信息数据
   * @param params
   * {
   *    "evaluate_user_id": 12121,  //评价的用户id
   *    "evaluate_message": "评价内容",
   *    "file_group_id": "附件分组id",
   *    "business_type": "业务类型, 2: 人员信息表, 3: 工作信息表",
   *    "business_id": "业务数据id",
   */
  releaseEvaluateInfo_create: async params => {
    const url = `${proxyUrl}/releaseEvaluateInfo/create`
    return axiosWraper.post(url, params)
  },
  /**
   * 删除消息发布-评价信息数据
   * @param params
   * {
   *    "id": 12121,
   */
  releaseEvaluateInfo_deleteById: async params => {
    const url = `${proxyUrl}/releaseEvaluateInfo/deleteById`
    return axiosWraper.post(url, params)
  },

}

export default nodeServerRelease
