
class DateUtils {
    constructor() {
        this.dateFormat();
        this.dayTime = 24*60*60*1000;
    }

    dateFormat() {
        Date.prototype.format = function (format) {
            var o = {

                "M+": this.getMonth() + 1, //month

                "d+": this.getDate(), //day

                "h+": this.getHours(), //hour

                "m+": this.getMinutes(), //minute

                "s+": this.getSeconds(), //second

                "q+": Math.floor((this.getMonth() + 3) / 3), //quarter

                "S": this.getMilliseconds() //millisecond

            }

            if (/(y+)/.test(format)) format = format.replace(RegExp.$1,

                (this.getFullYear() + "").substr(4 - RegExp.$1.length));

            for (var k in o) if (new RegExp("(" + k + ")").test(format))

                format = format.replace(RegExp.$1,

                    RegExp.$1.length == 1 ? o[k] :

                        ("00" + o[k]).substr(("" + o[k]).length));

            return format;

        }
    }


    getTodayDateTimeString(){
        let today = new Date().format('yyyy-MM-dd hh:mm:ss');
        return today;
    }

    getTodayDateString(){
        let today = new Date().format('yyyy-MM-dd');
        return today;
    }

    getDateStringByAfterDay(afterDay = 367){
        let resultDay = new Date(new Date().getTime() + afterDay * this.dayTime).format('yyyy-MM-dd');
        return resultDay;
    }


  getDateTimeString(date){
    let dateTimeString = date.format('yyyy-MM-dd hh:mm:ss');
    return dateTimeString;
  }

  getDateString(date){
    let dateString = date.format('yyyy-MM-dd');
    return dateString;
  }

}

module.exports = {
    dateUtils: new DateUtils()
}

