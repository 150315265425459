<template>
  <div class="manage-question-info" >
    <div class="table-header">
      <div class="query-item">
        <span class="query-item-label">分组类型: </span>
        <el-select  v-model="queryParams.group_type" class=" query-item-input m-2" clearable placeholder="选择">
          <template v-for="(item, index) in groupTypeList" :key="index" >
            <el-option :label="item.name" :value="item.code"></el-option>
          </template>
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">id: </span>
        <el-input class="query-item-input" v-model="queryParams.id" />
      </div>
      <div class="btn-group">
        <el-button type="primary" @click="searchBtn">查询</el-button>
        <el-button @click="resetBtn">重置</el-button>
        <el-button type="primary" @click="createBtn">新增</el-button>
      </div>

    </div>

    <div class="table-content custom-overflow">
      <el-table :data="pageData.rows" stripe style="width: 100%" show-overflow-tooltip >
        <el-table-column prop="sort_no" label="排序"  width="60"/>
        <el-table-column prop="group_name" label="标题" width="200" />
        <el-table-column prop="group_info" label="描述"  width="320"  />
        <el-table-column prop="group_type_enum" label="分组类型" />
        <el-table-column prop="create_time" label="创建时间" />
        <el-table-column prop="update_time" label="更新时间" />
        <el-table-column label="是否启用" >
          <template #default="scope">
            <el-switch size="small" :key="scope.$index" v-model="scope.row.is_active_enum" :loading="scope.row.is_active_load == true" @click="tableActiveChange(scope.$index, scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="small" type="primary" @click="showQuestionInfoEdit(scope.$index, scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="total">记录数: {{pageData.total}}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total" @current-change="currentChange" />
      </div>
    </div>
  </div>


  <el-dialog
    v-model="openQuestionInfoFlag"
    title="问题答疑信息"
    class="question-group-info-dialog"
  >
    <ManageQuestionInfoByEdit v-if="openQuestionInfoFlag"
                              :questionGroupId="questionGroupId"
                              :groupType="queryParams.group_type"
                              :lastSortNo="pageData.total">
    </ManageQuestionInfoByEdit>

  </el-dialog>

</template>

<script>

import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import { ElMessage } from 'element-plus'
import ManageQuestionInfoByEdit from '@/views/ManageQuestionInfoByEdit.vue'

export default {
  name: 'ManageParttimeInfo',
  components: {
    ManageQuestionInfoByEdit

  },
  data () {
    return {
      queryParams: {
        id: '',
        group_type: '1',
      },
      groupTypeList: [{code: '1', name: 'excel工具'}, {code: '2', name: 'word工具'}, {code: '3', name: '问题答疑'} ],

      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },

      openQuestionInfoFlag: false,
      questionGroupId: '',

    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {
        id: '',
        group_type: '1',
      }
      this.citySelect = false;

    },
    // 查询
    async searchBtn(){
      let searchParams = {
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        id: this.queryParams.id,
        group_type: this.queryParams.group_type,
        show_all: 1,
      }
      let res = await nodeServer.questionGroupInfo_getPage(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          // 分组类型
          let groupTypeMap = {1: 'excel工具', 2: 'word工具', 3: '问题答疑'};
          row.group_type_enum = groupTypeMap[row.group_type];
          // 是否启用
          let trueFalseMap = {0: false, 1: true};
          row.is_active_enum = trueFalseMap[row.is_active];
        }

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },

    async setActiveToService(row){
      // 是否启用
      row.is_active_load = true;
      // 直接调用接口
      let params = {id: row.id, is_active: row.is_active == 1 ? 0 : 1};
      let res = await nodeServer.questionGroupInfo_update(params);
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        if(params.is_active == 1){
          ElMessage.success('启用成功');
        } else {
          ElMessage.success('禁用成功');
        }
        await this.searchBtn();
      } else {
        ElMessage.error('系统出错');
        await this.searchBtn();
      }
    },
    tableActiveChange(index, row){
      this.setActiveToService(row);
      return true;
    },
    showQuestionInfoEdit(index, row){
      this.questionGroupId = row.id;
      this.openQuestionInfoFlag = true;
    },
    createBtn(){
      this.questionGroupId = null;
      this.openQuestionInfoFlag = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.manage-question-info{
  width: 100%;
  height: 100%;
  .table-header{
    height: 80px;
    line-height: 80px;
    display: flex;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .table-content{
    height: calc(100% - 80px);
    overflow-y: auto;
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }
  }
}
</style>
<style lang="scss">
.question-group-info-dialog{
  width: 1200px !important;
  .el-dialog__body{
    padding: 0;
  }
}
</style>
