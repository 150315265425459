<template>
  <router-view/>
</template>

<style lang="scss">
body{
  overflow: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  min-width: 1200PX;
  margin: 0;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

/* 设置滚动条的样式 */
.custom-overflow::-webkit-scrollbar {
  width: 6px;
}
/* 滚动槽 */
.custom-overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
/* 滚动条滑块 */
.custom-overflow::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: rgba(0, 0, 0, 0.5);
}
.custom-overflow::-webkit-scrollbar-thumb:window-inactive {
  //background: rgba(255, 255, 255, 0.4);
}
</style>
