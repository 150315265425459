/**
 * 环境配置
 */

/**
 * 包路径
 */
export const OUTPUT_DIR = process.env.VUE_APP_OUTPUT_DIR;

/**
 * 本地资源路径
 */
function getBaseUrl() {
    const NODE_ENV = process.env.NODE_ENV;
    let URL = ''; 
    // 开发和测试环境不变
    if (NODE_ENV === 'production') {
        URL = process.env.VUE_APP_OUTPUT_DIR
    }
    return URL
}
export const BASEURL = getBaseUrl();
