<template>
  <div class="manage-question-info-by-edit custom-overflow" >
    <el-form :model="entityData" label-width="auto" @submit.native.prevent ref="CreateQuestionInfoFormRef" >
      <div class="info-group">
        <div class="info-group-title">基础信息</div>
        <div class="info-row">
          <el-form-item class="info-item" label="标题" prop="title">
            <el-input class="info-item-input" v-model="entityData.group_name" />
          </el-form-item>
          <el-form-item class="info-item" label="类型" prop="title">
            <el-select v-model="entityData.group_type" class="info-item-input m-2" disabled clearable placeholder="选择">
              <el-option label="excel工具" value="1"></el-option>
              <el-option label="word工具" value="2"></el-option>
              <el-option label="问题答疑" value="3"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="info-row">
          <el-form-item class="info-item" label="内容描述" prop="introduction">
            <el-input class="info-item-input" v-model="entityData.group_info" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"  />
          </el-form-item>
        </div>
        <div class="info-row">
          <el-form-item class="info-item" label="是否启用" prop="introduction">
            <el-switch class="info-item-input" v-model="is_active_enum" />
          </el-form-item>
          <el-form-item class="info-item" label="排序" prop="introduction">
            <el-input-number class="info-item-input" v-model="entityData.sort_no"  :min="0" :max="1000"   controls-position="right"/>
          </el-form-item>
        </div>
        <div class="info-row">
          <el-form-item class="info-item" label="创建时间" prop="introduction">
            <el-input class="info-item-input" v-model="entityData.create_time" disabled />
          </el-form-item>
          <el-form-item class="info-item" label="更新时间" prop="introduction">
            <el-input class="info-item-input" v-model="entityData.update_time" disabled/>
          </el-form-item>
        </div>
        <div class="btn-group">
          <el-button type="primary" @click="saveGroupInfoBtn" >保存信息</el-button>
        </div>
      </div>

      <div class="info-group">
        <div class="info-group-title">详细步骤列表</div>
        <template v-for="(item, index) in detailList" :key="index" >
          <div class="detail-item">
            <div class="info-row">
              <el-form-item class="info-item" label="步骤序号" prop="introduction">
                <el-input-number class="info-item-input" v-model="item.sort_no"  :min="0" :max="1000"   controls-position="right"/>
              </el-form-item>
            </div>
            <div class="info-row">
              <el-form-item class="info-item" label="步骤描述" prop="introduction">
                <el-input class="info-item-input" v-model="item.detail_info" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }"  />
              </el-form-item>
              <el-form-item class="info-item" label="图片上传" prop="introduction">
                <el-upload
                  v-model:file-list="item.fileUiList"
                  action="/node_server/filesInfo/uploadFile"
                  list-type="picture-card"
                  accept=".jpg,.jpeg,.png,.gif"
                  :headers="uploadHeaders"
                  :on-success="uploadSuccess"
                  :on-exceed="uploadExceed"
                  :on-preview="handlePictureCardPreview"
                  :limit="1"
                >
                  <el-icon><Plus /></el-icon>
                </el-upload>

              </el-form-item>
            </div>
            <el-button class="detail-item-btn" type="default" @click="saveDetailInfoBtn(item)" >保存步骤</el-button>
          </div>
        </template>
        <el-button class="detail-item-btn" type="default" @click="addDetailInfoBtn" >添加步骤</el-button>
      </div>
    </el-form>
  </div>

  <el-dialog v-model="dialogImageVisible">
    <img :src="dialogImageUrl" style="max-width: 100%" alt="Preview Image" />
  </el-dialog>

</template>

<script>


import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import { ElMessage } from 'element-plus'
import { getCookie } from '@/net/cookies'
import defaultConfig from '@/config/defaultConfig'
export default {
  name: 'ManageQuestionInfoByEdit',
  components: {
  },
  props: ['questionGroupId', 'groupType', 'lastSortNo'],
  data () {
    return {
      isCreate: false, // 是否是新建
      is_active_enum: false,
      entityData: {
        id: '',
        group_name: '',
        group_info: '',
        sort_no: 0,
        group_type: 1,
        create_time: dateUtils.getTodayDateTimeString(),
        update_time: dateUtils.getTodayDateTimeString(),
        is_active: 0,
      },
      detailList: [
        /* {
          group_id: "分组id",
          detail_info: "安装报错xxx权限不足xxxxxxx",
          sort_no: 0,
          file_group_id: '',
          fileList: [],   // 附件的具体路径
          fileUiList: [],   // 展示用的 name , url
        } */
      ],

      uploadHeaders: {Authorization: ''},
      dialogImageVisible: false,
      dialogImageUrl: '',
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      if(!this.questionGroupId){
        this.isCreate = true;
        this.entityData.group_type = this.groupType;
        this.entityData.sort_no = this.lastSortNo + 1;
        console.log(this.lastSortNo)
        console.log(this.entityData.sort_no)
      }else{
        this.isCreate = false;
        this.searchInfo();
      }
      // 自动带上登录信息
      let token = getCookie("token");
      if(token){
        this.uploadHeaders.Authorization = token;
      }
    },

    // 查询
    async searchInfo(){
      let res = await nodeServer.questionGroupInfo_getDetailById({
        id: this.questionGroupId,
      });
      if(res.success && res.content){
        this.entityData = res.content.group;
        this.is_active_enum = this.entityData.is_active == 1 ? true : false;
        this.detailList = res.content.detailList;
        // 处理展示用的照片
        for(let detailItem of this.detailList){
          if(detailItem.fileList && detailItem.fileList[0]){
            detailItem.fileUiList = [{
              name: detailItem.fileList[0].file_name,
              url: defaultConfig.server_file_root + detailItem.fileList[0].file_path,
            }]
          }
        }
      }
    },
    //保存
    async saveGroupInfoBtn(){
      let entityData = JSON.parse(JSON.stringify(this.entityData));
      entityData.is_active = this.is_active_enum ? 1 : 0;
      //  调用接口插入
      if(this.isCreate && !this.entityData.id){
        let res = await nodeServer.questionGroupInfo_create(entityData);
        if(res.success){
          // 插入成功， 关闭弹窗，刷新列表
          ElMessage.success('创建成功');
          this.isCreate = false;
          this.questionGroupId = res.content;
        }
      } else if(this.entityData.id){
        // 更新
        let res = await nodeServer.questionGroupInfo_update(entityData);
        if(res.success){
          // 插入成功， 关闭弹窗，刷新列表
          ElMessage.success('保存成功');
        }
      }

      await this.searchInfo();
    },
    addDetailInfoBtn(){
      if(this.isCreate || !this.questionGroupId){
        ElMessage.error('请先保存信息')
        return;
      }
      this.detailList.push({
        group_id: this.questionGroupId,
        detail_info: "",
        sort_no: this.detailList.length + 1,
        file_group_id: '',
        fileUiList: [],   // 附件的具体路径
        fileList: [],   // 附件的具体路径
      })
    },
    uploadSuccess(res){
      if(res && res.success){
        // 遍历获取对应的内容
        let fileItem = res.content;
        for(let detailItem of this.detailList){
          if(detailItem.fileUiList && detailItem.fileUiList.length > 0){
            let fileUiItem = detailItem.fileUiList[0];
            if(fileUiItem.response && fileUiItem.response.success
                && fileUiItem.response.content.group_id == fileItem.group_id){
              //寻找到附件分组id一样的
              detailItem.file_group_id = fileItem.group_id;
              break;
            }
          }
        }
      }
    },
    uploadExceed(){
      ElMessage.error("限制最多只能上传一个图片")
    },
    handlePictureCardPreview(uploadFile){
      this.dialogImageVisible = true;
      this.dialogImageUrl = uploadFile.url;
    },
    async saveDetailInfoBtn(detailItem){
      let detailItem1 = JSON.parse(JSON.stringify(detailItem));
      detailItem1.fileList = null;
      detailItem1.fileUiList = null;
      //  调用接口插入
      if(!detailItem1.id){
        let res = await nodeServer.questionDetailInfo_create(detailItem1);
        if(res.success){
          // 插入成功， 关闭弹窗，刷新列表
          ElMessage.success('步骤创建成功');
          detailItem.id = res.content;
        }
      } else {
        // 更新
        let res = await nodeServer.questionDetailInfo_update(detailItem1);
        if(res.success){
          // 插入成功， 关闭弹窗，刷新列表
          ElMessage.success('步骤保存成功');
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.manage-question-info-by-edit{
  height: 600px;
  margin: 16px 32px;
  overflow-y: auto;

  padding: 0 20px 24px 20px;
  .info-group{
    .info-group-title{
      height: 24px;
      line-height: 22px;
      font-size: 14px;
      border-bottom: 1px solid #8c8c8c;
      margin-bottom: 16px;
    }
    .detail-item{
      border: 1px solid #8c8c8c;
      border-radius: 5px;
      margin-bottom: 12px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .detail-item-btn{
      margin-left: 32px;
    }
    .info-row{
      display: flex;
      .info-item{
        flex: 1;
        margin-right: 32px;
        margin-left: 32px;
        .keyword-input, .keyword-button-new-tag{
          width: 90px !important;
        }
        .el-tag{
          margin-right: 8px;
        }
        .tip-text{
          font-size: 12px;
          margin-left: 12px;
          color: #8c8c8c;
        }
      }
    }
  }
  .btn-group{
    text-align: center;
  }

}
</style>
