<template>
  <div class="manage-application-info custom-overflow" >
    <div class="table-header">
      <div class="query-item">
        <span class="query-item-label">ID: </span>
        <el-input class="query-item-input" v-model="queryParams.id" />
      </div>
      <div class="query-item">
        <span class="query-item-label">版本号: </span>
        <el-input class="query-item-input" v-model="queryParams.version" />
      </div>
      <div class="btn-group">
        <el-button type="primary" @click="searchBtn">查询</el-button>
        <el-button @click="resetBtn">重置</el-button>
        <el-button type="primary" @click="createBtn">新增</el-button>
      </div>
    </div>

    <el-table :data="pageData.rows" stripe style="width: 100%" show-overflow-tooltip>
      <el-table-column prop="id" label="ID"  width="60"  />
      <el-table-column prop="name" label="应用名" width="160" />
      <el-table-column prop="version" label="版本号"  width="120"/>
      <el-table-column prop="remarks" label="备注"  width="400" />
      <el-table-column prop="is_default_enum" label="是否默认"/>
      <el-table-column prop="create_time" label="创建时间" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="small" @click="setDefaultApplication(scope.$index, scope.row)" :disabled="scope.row && scope.row.is_default == 1">设为默认</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <div class="total">记录数: {{pageData.total}}条</div>
      <el-pagination background layout="prev, pager, next" :page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total" @current-change="currentChange" />
    </div>
  </div>


  <el-dialog
    v-model="openCreateInfoFlag"
    title="新建应用"
    class="create-info-dialog"
  >
    <div class="create-application-info">
      <div class="query-item">
        <div class="query-item-label">应用名: </div>
        <el-input class="query-item-input" v-model="createInfo.name" />
      </div>
      <div class="query-item">
        <div class="query-item-label">版本号: </div>
        <el-input class="query-item-input" v-model="createInfo.version" />
      </div>
      <div class="query-item">
        <div class="query-item-label">备注: </div>
        <el-input class="query-item-input" v-model="createInfo.remarks" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" />
      </div>
      <div class="query-item">
        <div class="query-item-label">附件上传: </div>
        <el-upload
          class="query-item-input"
          action="/node_server/filesInfo/uploadFile"
          :headers="uploadHeaders"
          :on-success="uploadSuccess"
          :limit="1"
        >
          <el-button type="primary">上传</el-button>
        </el-upload>
      </div>
      <div class="btn-group">
        <el-button type="primary" @click="saveInfoBtn" :disabled="saveInfoBtnDisabled">保存</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import { ElMessage } from 'element-plus'
import {getCookie} from "../net/cookies";
export default {
  name: 'ManageApplicationInfo',
  components: {

  },
  data () {
    return {
      queryParams: {
        id: '',
        version: '',
      },
      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      openCreateInfoFlag: false,
      createInfo:{
        name: "审计小哥excel工具",
        remarks: "",
        version: "",
        file_group_id: "",
      },
      saveInfoBtnDisabled: false,
      uploadHeaders: {Authorization: ''}
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      this.searchBtn();
      // 自动带上登录信息
      let token = getCookie("token");
      if(token){
        this.uploadHeaders.Authorization = token;
      }
    },
    resetBtn(){
      this.queryParams =  {
        id: '',
        version: '',
      }
    },
    // 查询
    async searchBtn(){
      let res = await nodeServer.applicationInfo_getPage({
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        id: this.queryParams.id,
        version: this.queryParams.version
      });
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          row.create_time = dateUtils.getDateTimeString(new Date(row.create_time));
          row.update_time = dateUtils.getDateTimeString(new Date(row.update_time));
          row.order_cost = row.order_cost ? row.order_cost * 0.01 : 0;
          // 交易状态，枚举值
          let isDefaultMap = {
            0: '否',
            1: '是',
          }
          row.is_default_enum = isDefaultMap[row.is_default];
        }

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
    createBtn(){
      this.openCreateInfoFlag = true;
      //重置表单
      this. createInfo ={
        name: "审计小哥excel工具",
        remarks: "",
        version: "",
        file_group_id: "",
      }
      this.saveInfoBtnDisabled = false
    },
    async saveInfoBtn(){
      this.saveInfoBtnDisabled = true;
      let res =  await nodeServer.applicationInfo_create({
        name: this.createInfo.name,
        remarks: this.createInfo.remarks,
        version: this.createInfo.version,
        file_group_id: this.createInfo.file_group_id
      })
      if(res && res.success){
        ElMessage.success('创建成功');
        // 关闭窗口，刷新页面
        this.openCreateInfoFlag = false;
        this.searchBtn();
      }
    },
    uploadSuccess(res){
      if(res && res.success){
        this.createInfo.file_group_id = res.content.group_id;
      }
    },
    async setDefaultApplication(index, row){
      let applicationId = row.id;
      let res = await nodeServer.applicationInfo_setDefaultById({id: applicationId})
      if(res && res.success){
        ElMessage.success('设置成功');
        // 关闭窗口，刷新页面
        this.searchBtn();
      }else{
        ElMessage.error('设置失败');
      }

    }
  }
}
</script>
<style lang="scss" scoped>
.manage-application-info{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  height: 100%;
  .table-header{
    height: 80px;
    line-height: 80px;
    display: flex;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .pagination{
    margin-top: 1rem;
    float: right;
    display: flex;
    .el-pagination{
      margin-right: 32px;
    }
    .total{
      line-height: 40px;
      font-size: 14px;
      margin-right: 24px;
    }
  }
}
.create-application-info{
  padding-bottom: 32px;
  .query-item{
    display: flex;
    line-height: 2rem;
    margin: 24px 64px;
    .query-item-label{
      text-align: right;
      width: 120px;
    }
    .query-item-input{
      margin-left: 32px;
      width: 360px;
    }
  }
  .btn-group{
    text-align: center;
  }
}
</style>

<style lang="scss">
  .create-info-dialog{
    width: 800px !important;
    .el-dialog__body{
      padding: 0;
    }
  }
</style>
