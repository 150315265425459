import axios from './http'
import {getCookie} from "./cookies";

/**
 * class AxiosWraper
 */
class AxiosWraper {
  constructor () {
    // this.proxyKeys = [{
    //     pre: 'http://192.168.3.203:7280',
    //     // baseKey: '/Models/'
    // }]
    this.proxyKeys = []
    // this.getConfigProxyUrl();
  }

  getConfigProxyUrl (param) {
    const _t = this
    _t.proxyKeys = []
    const key = param
    const res = axios.get(`/agsupport-rest/agsupport/projectManager/get?name=${key}`)
    res.then(function (data) {
      if (data.success) {
        const arr = JSON.parse(data.content).netConfig
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].key === 'pre') {
            const o = {
              pre: arr[i].value
            }
            _t.proxyKeys.push(o)
          }
        }
      }
    })
  }

  getProxyUrl (url) {
    var resUrl = url
    for (var i = 0; i < this.proxyKeys.length; i++) {
      var tv = url.startsWith(this.proxyKeys[i].pre)
      if (!tv) continue
      var turl = url.substring(this.proxyKeys[i].pre.length + 1)
      resUrl = `${turl}`
      break
    }
    return resUrl
  }

  getData (url, params) {
    var resUrl = this.getProxyUrl(url)
    return axios.get(resUrl, {
      params: params
    })
  }

  getDataByPost (url, data) {
    var resUrl = this.getProxyUrl(url)
    return axios({
      method: 'POST',
      url: resUrl,
      data: data,
      timeout: 1000 * 60 * 5
    })
  }

  delete (url, data) {
    var resUrl = this.getProxyUrl(url)
    return axios.delete(resUrl, {
      data: data,
      timeout: 1000 * 60 * 5
    })
  }

  put (url, data) {
    var resUrl = this.getProxyUrl(url)
    return axios.put(resUrl, data, {
      timeout: 1000 * 60 * 5
    })
  }

  post (url, data) {
    return axios({
      method: 'POST',
      url: url,
      data: data,
      timeout: 1000 * 60 * 5
    })
  }

  postJson (url, data) {
    return axios({
      method: 'POST',
      url: url,
      data: data,
      timeout: 1000 * 60 * 5,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  postForm (url, data) {
    return axios({
      method: 'POST',
      url: url,
      data: data,
      timeout: 1000 * 60 * 5,
      headers: {
        'Content-Type': 'X-WWW-FORM-URLENCODED'
      }
    })
  }

  deleteDate (url, data) {
    return axios.delete(url, {
      params: data
    })
  }

  get (url) {
    return axios({
      method: 'GET',
      url: url
    })
  }

  putDate (url, data) {
    return axios({
      method: 'PUT',
      url: url,
      data: data
    })
  }

  getMaterialType (data) {
    return axios.get('/agsupport-rest/agsupport/buildingComponent/get', {
      params: data
    })
  }

  /**
     * 获得兄弟文件(可以深度)的地址
     * @param {*} url
     */
  getSibleFileUrl (url, fileName) {
    return url.substr(0, url.lastIndexOf('/')) + '/' + fileName
  }

  updateCookieToken(){
    // 自动带上登录信息
    let token = getCookie("token");
    if(token){
      axios.defaults.headers.post['Authorization'] = token;
      axios.defaults.headers.get['Authorization'] = token;
    }
  }

}

export default new AxiosWraper()
