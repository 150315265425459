<template>
  <div class="weixin-login-content" id="weixinLogin"></div>
  <div class="hideBtn" id="weixinLoginSuccessBtn" @click="loginSuccess"></div>
</template>

<script>

export default {
  name: 'WeixinLogin',
  components: {

  },
  data () {
    return {

    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      // 创建微信二维码
      var obj = new WxLogin({
        self_redirect:true,
        id:"weixinLogin",
        appid: "wx41749ba1e62519d2",
        scope: "snsapi_login",
        redirect_uri: "https://www.qiushuict.com/weixin_login_success",
        state: "",
        style: "",
        href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtmb250LXNpemU6IDE4cHh9Ci5pbXBvd2VyQm94IC5xcmNvZGUge3dpZHRoOiAyMDBweDt9Ci5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjAwcHg7fQ=="
      });
    },
    loginSuccess(){
      // 刷新页面
      location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.weixin-login-content{
  height: 400px;
  width: 400px;
  .hideBtn{
    display: none;
  }
}
</style>
