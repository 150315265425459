<template>
  <div class="main-view">
    <div class="content">
      审计小哥视频课程列表
      <div>1、视频介绍</div>
      <div>2、微信公众号二维码展示</div>
      <div>3、微信号二维码展示</div>
    </div>
    <div class="course-list">课程列表放置</div>
  </div>
</template>

<script>

export default {
  name: 'Course',
  components: {

  },
  data () {
    return {
      photoList: []
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {

    },
  }
}
</script>
<style lang="scss" scoped>
.main-view{
  .content{
    background: #91caff;
    width: 100%;
    height: 600px;
  }
}
</style>
