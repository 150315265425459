<template>
  <div class="release-info" >
    <div class="release-feedback" @click="clickFeedbackShow" v-if="hasUserLogin">
      <el-icon><ChatDotRound /></el-icon>
      <br />
      <span>反馈意见</span>
    </div>
    <div class="content">
      <div class="header-content">
        <div class="header-content-left">
          <template v-for="(item) in tabList">
            <div class="header-item" :class="{active: this.tabSelect == item.index}" @click="handleClickTab(item)">
              <span>{{ item.name }}</span>
            </div>
          </template>
        </div>
        <div class="header-content-right">
          <el-button class="header-content-right-release-btn" type="primary"  @click="clickPersonalCenter">
            <el-icon><Plus /></el-icon>我要发布
          </el-button>
        </div>
      </div>
      <div class="main-content">
        <ReleaseJobInfo v-if="this.tabSelect == 0" :key="this.tabList[0].key"></ReleaseJobInfo>
        <ReleaseStaffInfo v-if="this.tabSelect == 1" :key="this.tabList[1].key" ></ReleaseStaffInfo>
      </div>
    </div>
  </div>


  <el-dialog
    v-model="openFeedbackInfoFlag"
    title="反馈意见"
    class="feedback-info-dialog"
    :close-on-click-modal="true"
  >
    <div class="feedback-info-dialog-content">
      <el-form :model="showFeedbackInfo" label-width="auto" :rules="showFeedbackRules" @submit.native.prevent ref="ShowBaseInfoFormRef" >
        <div class="info-group">
          <div class="info-row">
            <el-form-item class="info-item" label="反馈意见" prop="feedback_content">
              <el-input class="info-item-input" v-model="showFeedbackInfo.feedback_content" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }"  />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="btn-group">
        <el-button type="primary" @click="saveFeedbackInfoBtn" >保存</el-button>
      </div>
    </div>
  </el-dialog>

</template>

<script>

import { ElMessage } from 'element-plus'
import ReleaseJobInfo from '@/views/ReleaseJobInfo.vue'
import ReleaseStaffInfo from '@/views/ReleaseStaffInfo.vue'
import { getCookie } from '@/net/cookies'
import nodeServerRelease from '@/net/nodeServerRelease'
import nodeServer from '@/net/nodeServer'

export default {
  name: 'ReleaseInfo',
  components: {
    ReleaseStaffInfo,
    ReleaseJobInfo,
  },
  data () {
    return {
      tabList: [
        {index: 0, name: '工作信息', key: 0},
        {index: 1, name: '人员信息', key: 1},
      ],
      tabSelect: 0,

      // 反馈意见
      hasUserLogin: false,
      openFeedbackInfoFlag: false,
      showFeedbackInfo: {feedback_content: ''},
      showFeedbackRules: {
        feedback_content: [
          { required: true, message: '请输入反馈意见', trigger: 'blur' },
          { max: 200, message: '最大长度200', trigger: 'blur' },
        ],
      },
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      this.checkUserLogin();
    },
    checkUserLogin(){
      // 获取用户信息
      try{
        let userInfoStr = getCookie("userInfo");
        let userInfo = JSON.parse(userInfoStr);
        if(userInfo.id){
          this.hasUserLogin = true;
        }
      } catch (e) {

      }
    },
    handleClickTab(tabItem){
      if(this.tabSelect == tabItem.index){
        tabItem.key = tabItem.index + '_' + new Date().getTime();
      }
      this.tabSelect = tabItem.index;
    },
    clickPersonalCenter(){
      // 判断用户是否登录
      let token = getCookie("token");
      if(!token){
        // 没有登录， 打开登录页面
        this.$emit('childEmit', 1);
        ElMessage('请先登录.')
        return;
      } else {
        // 进入个人中心，维护信息
        this.$router.push({ name: 'ReleasePersonalCenter'})
      }
    },
    clickFeedbackShow(){
      this.showFeedbackInfo = {feedback_content: ''};
      this.openFeedbackInfoFlag = true;
    },
    async saveFeedbackInfoBtn(){
      let feedback_content = this.showFeedbackInfo.feedback_content;
      if(!feedback_content){
        ElMessage.error('请先填写反馈信息');
        return;
      } else if(feedback_content.length > 200){
        ElMessage.error('反馈信息最大长度200');
        return;
      }

      let entityInfo = JSON.parse(JSON.stringify(this.showFeedbackInfo));
      //  调用接口插入
      let res = await nodeServer.feedbackInfo_create(entityInfo);
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        ElMessage.success('反馈成功');
        // 关闭弹窗
        this.openFeedbackInfoFlag = false;
        await this.initBaseInfo();
      } else {
        if(res.message && res.message.length < 50){
          ElMessage.error(res.message);
        }else{
          ElMessage.error('反馈失败，请联系小哥');
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.release-info{
  width: 100%;
  font-size: 14px;
  position: relative;
  .release-feedback{
    position: absolute;
    bottom: 60px;
    right: 60px;
    text-align: center;
    border-radius: 50%;
    border: solid 1px #f0f0f0;
    width: 60px;
    height: 60px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 8px rgba(140, 140, 140, 0.4); /* 水平偏移 垂直偏移 模糊半径 颜色 */
    }
    .el-icon{
      font-size: 24px;
      margin-top: 8px;
      line-height: 30px;
    }
  }
  .content {
    min-height: 800px;
    margin: 24px 240px 48px 240px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);  /* 水平偏移 垂直偏移 模糊半径 颜色 */
    border-radius: 20px;
    .header-content {
      height: 60px;
      display: flex;
      align-items: center; /* 垂直居中 */
      .header-content-left {
        height: 60px;
        width: calc(100% - 200px);
        display: flex;
        align-items: center; /* 垂直居中 */
        .header-item {
          margin-left: 32px;
          text-align: center;
          font-size: 16px;
          cursor: pointer;
          height: 36px;
          line-height: 36px;
          width: 120px;
          border-radius: 8px;
          color: #262626;
          background: linear-gradient(to bottom, #f0f0f0, #fafafa);

          &:hover {
            box-shadow: 0 0 8px rgba(140, 140, 140, 0.4); /* 水平偏移 垂直偏移 模糊半径 颜色 */
          }

          &.active {
            box-shadow: 0 0 8px rgba(140, 140, 140, 0.8); /* 水平偏移 垂直偏移 模糊半径 颜色 */
          }
        }
      }

      .header-content-right {
        height: 60px;
        width: 200px;
        display: flex;
        align-items: center; /* 垂直居中 */
        .header-content-right-release-btn{
          margin-left: 32px;
          font-size: 16px;
          cursor: pointer;
          height: 36px;
          line-height: 36px;
          width: 120px;
          border-radius: 8px;
          .el-icon{
            margin-right: 2px;
            font-size: 16px;
          }
        }
      }
    }
    .main-content{
      width: 100%;
      min-height: 600px;
      background: linear-gradient(to bottom, #f0f0f0, #fff 50%);
    }
  }
}

//弹窗
.feedback-info-dialog-content{
  padding: 0 20px 24px 20px;
  .info-group{
    .info-group-title{
      height: 24px;
      line-height: 22px;
      font-size: 14px;
      border-bottom: 1px solid #8c8c8c;
      margin-bottom: 16px;
    }
    .info-row{
      display: flex;
      .info-item{
        flex: 1;
        margin-right: 32px;
        .tag-input, .keyword-button-new-tag{
          width: 160px !important;
        }
        .el-tag{
          margin-right: 8px;
          margin-bottom: 4px;
        }
        .tip-text{
          font-size: 12px;
          margin-left: 12px;
          color: #8c8c8c;
        }
      }
    }
  }
  .btn-group{
    text-align: center;
  }
}
</style>
<style lang="scss">
.feedback-info-dialog{
  width: 800px !important;
  .el-dialog__body{
    padding: 0;
  }
}
</style>
