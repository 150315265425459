<template>
  <div class="manage-release-base-info" >
    <div class="table-header">
      <div class="query-item">
        <span class="query-item-label">用户id: </span>
        <el-input class="query-item-input" v-model="queryParams.user_id" />
      </div>
      <div class="query-item">
        <span class="query-item-label">审核状态: </span>
        <el-select  v-model="queryParams.examine_status" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="审核通过" :value="1"></el-option>
          <el-option label="审核不通过" :value="2"></el-option>
        </el-select>
      </div>
      <div class="btn-group">
        <el-button type="primary" @click="searchBtn">查询</el-button>
        <el-button @click="resetBtn">重置</el-button>
      </div>
    </div>

    <div class="table-content custom-overflow">
      <el-table :data="pageData.rows" stripe style="width: 100%" show-overflow-tooltip >
        <el-table-column prop="user_id" label="用户id"  width="80" />
        <el-table-column prop="name" label="昵称"   />
        <el-table-column prop="phone_number" label="电话" />
        <el-table-column prop="wechat" label="微信号" />
        <el-table-column prop="sex_enum" label="性别"  width="80" />
        <el-table-column label="审核状态"  width="120">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span :class="{'success-row': scope.row.examine_status == 1, 'warning-row': scope.row.examine_status == 2, 'cancel-row': scope.row.examine_status == 3}">
                {{ scope.row.examine_status_enum }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" />
      </el-table>
      <div class="pagination">
        <div class="total">记录数: {{pageData.total}}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total" @current-change="currentChange" />
      </div>
    </div>
  </div>
</template>

<script>

import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import nodeServerRelease from '@/net/nodeServerRelease'

export default {
  name: 'ManageReleaseBaseInfo',
  components: {

  },
  data () {
    return {
      dictInfo: {},
      queryParams: {
        user_id: '',
        examine_status: '',
      },

      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },

    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    async init () {
      //  初始化行政区划
      await this.initdictInfo();

      await this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {
        user_id: '',
        examine_status: '',
      }
    },
    async initdictInfo(){
      let res = await nodeServer.regionInfo_getDictInfo();
      if(res.success && res.content){
        this.dictInfo = res.content;
      }
    },
    // 查询
    async searchBtn(){
      let searchParams = {
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        user_id: this.queryParams.user_id,
        examine_status: this.queryParams.examine_status,
      }
      let res = await nodeServerRelease.releaseBaseInfo_getPage(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          // 审核状态
          row.examine_status_enum = this.getDictNameByDictCode('release_examine_status', row.examine_status);

          // 是否启用
          let sexMap = {1: '男', 2: '女'};
          row.sex_enum = sexMap[row.sex];
        }

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
    getDictNameByDictCode(dictType, dictCode){
      if(this.dictInfo && this.dictInfo[dictType] && this.dictInfo[dictType][dictCode]){
        return this.dictInfo[dictType][dictCode];
      }
      return '';
    },
  }
}
</script>
<style lang="scss" scoped>
.manage-release-base-info{
  width: 100%;
  height: 100%;
  .table-header{
    height: 80px;
    line-height: 80px;
    display: flex;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .table-content{
    height: calc(100% - 80px);
    overflow-y: auto;
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }

    .success-row{
      color: #00c48f;
    }
    .warning-row{
      color: #f44336;
    }
    .cancel-row{
      color: #ff9f00;
    }
  }
}
</style>
<style lang="scss">
  .order-info-dialog{
    width: 1200px !important;
    .el-dialog__body{
      padding: 0;
    }
  }
</style>
