<template>
  <div class="release-job-info" v-loading="this.globalLoading">
    <div class="info-content">
      <div class="main-navigation">
        <div class="main-search-box">
          <div class="query-item">
            <span class="query-item-label">区划: </span>
            <el-cascader v-model="queryParams.regionModel" :options="regionTree"
                         :props="{ checkStrictly: true, value: 'region_code', label: 'region_name'}"
                         placeholder="选择城市" clearable @change="searchBtn()" />
          </div>
          <div class="query-item">
            <span class="query-item-label">工作类型: </span>
            <el-select  v-model="queryParams.full_time_type" class=" query-item-input m-2" clearable placeholder="不限" @change="searchBtn()">
              <el-option label="兼职" :value="0"></el-option>
              <el-option label="全职" :value="1"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="main-show">
        <div class="main-show-nodata" v-if="pageData.rows.length == 0">暂无数据</div>
        <div class="main-show-left custom-overflow" @scroll="handleScroll">
          <template v-for="(item, index) in pageData.rows" :key="index" >
            <div class="left-item" :class = "{active: this.selectPageIndex == index}" @click="clickPageItem(index)">
              <!-- 兼职状态-->
              <div class="left-item-full-time" >
                {{ item.full_time_type_enum }}
              </div>

              <div class="left-item-title">{{ item.title }}</div>
              <div class="left-item-row">
                <i class="iconfont icon-xinzi" style="color: rgb(254, 131, 12)"></i>
                <span>薪资情况：</span>
                <span>{{item.salary_enum}}</span>
              </div>
              <!-- 兼职才有项目时间-->
              <div class="left-item-row" v-if="item.full_time_type == 0">
                <i class="iconfont icon-shijian" style="color: rgb(22, 212, 107)"></i>
                <span>项目时间：</span>
                <span>{{item.entry_time_enum}}</span>
                <span>至</span>
                <span>{{item.end_time_enum}}</span>
              </div>
              <div class="left-item-foot">
                <div class="left-item-foot-name">
                  <i class="iconfont icon-yonghufill"></i>
                  <span>{{ item.base_name }}</span>
                </div>
                <div class="left-item-foot-address">
                  <i class="iconfont icon-dingwei" style="color: rgb(56,155,255)"></i>
                  <span>{{ item.region_name }}</span>
                </div>
              </div>
            </div>
          </template>
          <div class="left-item-loading" v-loading="true" v-if="this.pageLoading" ></div>
        </div>
        <div class="main-show-right" v-if="this.selectPageItem != null" v-loading="this.selectPageItemLoading">
          <div class="right-header">
            <!-- 兼职状态-->
            <div class="right-header-full-time" >
              {{ selectPageItem.full_time_type_enum }}
            </div>
            <div class="right-header-evaluate" v-if="this.checkHaveEvaluatePermission()">
              <el-button class="right-header-evaluate-btn" @click="clickAddEvaluate(selectPageItem)">
                <el-icon><ChatLineRound /></el-icon>
                我要评论
              </el-button>
            </div>

            <div class="right-header-title ellipse">{{ selectPageItem.title }}</div>
            <div class="right-header-row">
              <i class="iconfont icon-xinzi" style="color: rgb(254, 131, 12)"></i>
              <span>薪资情况：</span>
              <span>{{selectPageItem.salary_enum}}</span>
            </div>
            <!-- 兼职才有项目时间-->
            <div class="right-header-row" v-if="selectPageItem.full_time_type == 0">
              <i class="iconfont icon-shijian" style="color: rgb(22, 212, 107)"></i>
              <span>项目时间：</span>
              <span>{{selectPageItem.entry_time_enum}}</span>
              <span>至</span>
              <span>{{selectPageItem.end_time_enum}}</span>
            </div>
            <div class="right-header-link">
              <div class="right-header-link-item">
                <i class="iconfont icon-dingwei" style="color: rgb(56,155,255)"></i>
                <span>{{ selectPageItem.region_name }}</span>
              </div>
              <div class="right-header-link-item" v-if="selectPageItem.base_name">
                <i class="iconfont icon-yonghufill"></i>
                <span>{{ selectPageItem.base_name }}</span>
              </div>
              <div class="right-header-link-item" v-if="selectPageItem.base_wechat">
                <i class="iconfont icon-weixin" style="color: rgb(22,212,107)"></i>
                <span>{{ selectPageItem.base_wechat }}</span>
              </div>
              <div class="right-header-link-item" v-if="selectPageItem.base_phone_number">
                <i class="iconfont icon-dianhua" style="color: rgb(255,144,0)"></i>
                <span>{{ selectPageItem.base_phone_number }}</span>
              </div>
            </div>
          </div>
          <div class="right-content custom-overflow">
            <div class="right-content-titleinfo">信息描述</div>
            <div class="right-content-introduction" v-html="this.formattedText(selectPageItem.introduction)"></div>
            <div class="right-content-evaluate" v-if="this.pageEvaluateData.total > 0">
              <div class="right-content-titleinfo">评论信息（ {{this.pageEvaluateData.total}} 条 ）</div>
              <div class="right-content-evaluate-item" v-for="(item, index) in pageEvaluateData.rows" :key="index" >
                <div class="right-content-evaluate-item-message" v-html="this.formattedText(item.evaluate_message)"></div>
                <div class="right-content-evaluate-item-images" v-if="item.imageUrlList && item.imageUrlList.length > 0">
                  <template v-for="(item1, index1) in item.imageUrlList" :key="index1" >
                    <el-image
                      :src="item1"
                      :preview-src-list="item.imageUrlList"
                      fit="cover"
                      hide-on-click-modal
                      :initial-index="index1"
                    />
                  </template>
                </div>
                <div class="right-content-evaluate-item-footer">
                  {{item.create_time}}
                </div>
              </div>
              <div class="right-content-evaluate-more" @click="clickEvaluateMoreBtn" v-if="pageEvaluateData.total > pageEvaluateData.rows.length">
                <el-icon><ArrowDownBold /></el-icon>
                加载更多评论
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <el-dialog
    v-model="openEvaluateInfoFlag"
    title="评价"
    class="edit-info-dialog"
    :close-on-click-modal="false"
    @closed="closedEvaluateInfoFlag"
  >
    <div class="edit-info-dialog-content">
      <el-form :model="showEvaluateInfo" label-width="auto" :rules="showEvaluateRules" @submit.native.prevent ref="ShowEvaluateInfoFormRef" >
        <div class="info-group">
          <div class="info-row">
            <el-form-item class="info-item" label="评价" prop="evaluate_message">
              <el-input class="info-item-input" v-model="showEvaluateInfo.evaluate_message" type="textarea" :autosize="{ minRows: 5, maxRows: 10 }"  />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="图片" prop="file_group_id">
              <el-upload
                v-model:file-list="showEvaluateInfo.fileUiList"
                action="/node_server/filesInfo/uploadImageByGroupId"
                list-type="picture-card"
                accept=".jpg,.jpeg,.png"
                :data="evaluateUploadData"
                :headers="evaluateUploadHeaders"
                :on-success="evaluateUploadSuccess"
                :on-remove	="evaluateUploadRemove"
                :on-exceed="evaluateUploadExceed"
                :on-preview="evaluateHandlePictureCardPreview"
                :limit="3"
              >
                <el-icon><Plus /></el-icon>
              </el-upload>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="btn-group">
        <el-button type="primary" @click="saveEvaluateInfoBtn" >保存</el-button>
      </div>
    </div>
  </el-dialog>

</template>

<script>

import { dateUtils } from '@/utils/dateUtils'
import nodeServer from '@/net/nodeServer'
import nodeServerRelease from '@/net/nodeServerRelease'
import { getCookie } from '@/net/cookies'
import { ElMessage } from 'element-plus'
import { defaultUtils } from '@/utils/defaultUtils'
import defaultConfig from '@/config/defaultConfig'

export default {
  name: 'ReleaseJobInfo',
  components: {

  },
  data () {
    return {
      // 用户登录信息
      userInfo: {},
      releaseBaseInfo: {},

      globalLoading: false,
      dictInfo: {},
      regionTree: [],

      queryParams: {
        province: '',
        city: '',
        regionModel: [],  // 省、市
        full_time_type: null,  //  工作类型，0兼职，1全职
      },

      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      selectPageIndex: 0,
      selectPageItem: null,
      pageLoading: false,
      selectPageItemLoading: false,

      // 评价信息
      pageEvaluateData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 5,
      },

      // 添加评论
      openEvaluateInfoFlag: false,
      openEvaluateHasSave: false,
      showEvaluateInfo: {
        evaluate_user_id : null,
        evaluate_message : '内容',
        file_group_id    : null,
        business_type    : 3,
        business_id      : null,
        examine_status   : 0,
        examine_message  : '',
        // 附件列表
        fileUiList: [],
      },
      evaluateUploadHeaders: {Authorization: ''},
      evaluateUploadData: {groupId: ''},
      showEvaluateRules: {
        evaluate_message: [
          { required: true, message: '请输入评价内容', trigger: 'blur' },
          { max: 1000, message: '最大长度1000', trigger: 'blur' },
        ],
      },
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    async init () {
      this.globalLoading = true;
      // 获取用户信息
      this.initUserInfo();
      await this.initReleaseBaseInfo();
      //  初始化行政区划
      await this.initDictInfo();
      await this.initRegionTree();

      await this.searchBtn();

      this.globalLoading =false;
    },
    initUserInfo () {
      // 获取用户信息
      try{
        let userInfoStr = getCookie("userInfo");
        let userInfo = JSON.parse(userInfoStr);
        this.userInfo = userInfo;
      } catch (e) {

      }
    },
    async initReleaseBaseInfo(){
      let res = await nodeServerRelease.releaseBaseInfo_getOneBySelf({});
      if(res.success && res.content){
        this.releaseBaseInfo = res.content;
      }
    },
    // 检查用户id不是自己,且自己已经登录
    checkUserIdNoSelf(userId){
      if(this.userInfo && this.userInfo.id && this.userInfo.id != userId){
        return true;
      }
      return false;
    },
    checkHaveEvaluatePermission(){
      // 判断已经登录，且不是自己
      if(this.checkUserIdNoSelf(this.selectPageItem.user_id)){
        // 判断自己已经维护好个人基础信息，且审核通过了
        if(this.releaseBaseInfo && this.releaseBaseInfo.examine_status == 1){
          return true;
        }
      }
      return false
    },
    async initDictInfo(){
      let res = await nodeServer.regionInfo_getDictInfo();
      if(res.success && res.content){
        this.dictInfo = res.content;
      }
    },
    getDictNameByDictCode(dictType, dictCode){
      if(this.dictInfo && this.dictInfo[dictType] && this.dictInfo[dictType][dictCode]){
        return this.dictInfo[dictType][dictCode];
      }
      return '';
    },
    async initRegionTree(){
      let res = await nodeServer.regionInfo_getRegionTree({});
      if(res.success && res.content){
        this.regionTree = res.content;
      }
    },
    // 处理省份和城市的字段翻译
    translateRegionCode(item){
      if(item && item.province){
        // 遍历获取省份
        for(let regionItem of this.regionTree){
          if(item.province == regionItem.region_code){
            item.province_name = regionItem.region_name;
            // 遍历获取城市
            if(item.city && regionItem.children){
              for(let cityItem of regionItem.children){
                if(item.city == cityItem.region_code) {
                  item.city_name = cityItem.region_name;
                  break;
                }
              }
            }
            break;
          }
        }
        item.region_name = item.province_name;
        if(item.city_name){
          item.region_name += ' - ' + item.city_name;
        }
      }
    },
    // 查询
    async searchBtn(pageNum = 1, isConcat = false){
      // 不是合并数据的，默认选择第一个
      if(!isConcat){
        this.selectPageIndex = 0;
      }
      this.pageData.pageNum = pageNum;
      // 处理区划信息
      if(this.queryParams.regionModel && this.queryParams.regionModel.length > 0){
        this.queryParams.province = this.queryParams.regionModel[0];
        this.queryParams.city = this.queryParams.regionModel[1] ? this.queryParams.regionModel[1] : '';
      }else{
        this.queryParams.province = '';
        this.queryParams.city = '';
      }
      let searchParams = {
        pageNum: this.pageData.pageNum,
        pageSize: this.pageData.pageSize,
        province: this.queryParams.province,
        city: this.queryParams.city,
        full_time_type: this.queryParams.full_time_type,
      }
      let res = await nodeServerRelease.releaseJobInfo_getPage(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          // 审核状态
          row.examine_status_enum = this.getDictNameByDictCode('release_examine_status', row.examine_status);
          // 兼职状态
          row.full_time_type_enum = this.getDictNameByDictCode('full_time_type', row.full_time_type);
          // 薪资情况
          if(row.full_time_type == 0){
            row.salary_enum = `日薪${row.salary}元`;
          }else{
            row.salary_enum = `年薪${row.salary}万元及以上`;
          }
          // 行政区划
          this.translateRegionCode(row);
          // 项目时间
          if(row.full_time_type == 0){
            row.entry_time_enum = dateUtils.getDateString(new Date(row.entry_time));
            row.end_time_enum = dateUtils.getDateString(new Date(row.end_time));
          }
        }
        // 是否是合并的
        if(isConcat){
          this.pageData.rows = this.pageData.rows.concat(res.content.rows);
        } else {
          this.pageData.rows = res.content.rows;
          if(this.pageData.rows && this.pageData.rows.length > 0){
            this.clickPageItem(0);
          }else{
            this.selectPageItem = null;
          }
        }
        this.pageData.total = res.content.total;
      }
    },
    // 点击列表信息
    async clickPageItem(index){
      this.selectPageItemLoading = true;

      this.selectPageIndex = index;
      // 展示信息
      this.selectPageItem = this.pageData.rows[index];
      // 处理数据
      // 地址处理
      this.selectPageItem.address_name = this.selectPageItem.province_name;
      if(this.selectPageItem.city_name){
        this.selectPageItem.address_name += ' - ' + this.selectPageItem.city_name;
      }
      // 获取评论信息
      await this.getEvaluatePage();

      this.selectPageItemLoading = false;
    },
    formattedText(rawText) {
      return rawText.replace(/\n/g, "<br />");
    },
    async handleScroll(event){
      const dialog = event.target;
      if(dialog.scrollHeight - dialog.scrollTop < dialog.clientHeight * 1.02){
        if(!this.pageLoading && this.pageData.rows.length < this.pageData.total){
          this.pageLoading = true;
          await this.searchBtn(this.pageData.pageNum + 1, true);
          this.pageLoading = false;
        }
      }
    },
    async clickEvaluateMoreBtn(){
      this.selectPageItemLoading = true;
      if(this.pageEvaluateData.rows.length < this.pageEvaluateData.total){
        await this.getEvaluatePage(this.pageEvaluateData.pageNum + 1, true);
      }
      this.selectPageItemLoading = false;
    },
    async getEvaluatePage(pageNum = 1, isConcat =false){
      this.pageEvaluateData.pageNum = pageNum;

      let searchParams = {
        pageNum: this.pageEvaluateData.pageNum,
        pageSize: this.pageEvaluateData.pageSize,
        business_type: 3,
        business_id: this.selectPageItem.id,
      }
      let res = await nodeServerRelease.releaseEvaluateInfo_getPage(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          // 审核状态
          row.examine_status_enum = this.getDictNameByDictCode('release_examine_status', row.examine_status);
          row.business_type_enum = this.getDictNameByDictCode('release_business_type', row.business_type);
          // 处理附件列表
          if(row.fileList && row.fileList.length > 0){
            row.imageUrl = '';
            row.imageUrlList = [];
            for(let fileItem of row.fileList){
              let imageUrl = defaultConfig.server_file_root + fileItem.file_path;
              row.imageUrlList.push(imageUrl);
            }
            row.imageUrl = row.imageUrlList[0];
          }
        }
        // 是否是合并的
        if(isConcat){
          this.pageEvaluateData.rows = this.pageEvaluateData.rows.concat(res.content.rows);
        } else {
          this.pageEvaluateData.rows = res.content.rows;
        }
        this.pageEvaluateData.total = res.content.total;
      }
    },
    clickAddEvaluate(item){
      this.openEvaluateInfoFlag = true;
      this.openEvaluateHasSave = false;
      // 自动带上登录信息
      if(!this.evaluateUploadHeaders.Authorization){
        let token = getCookie("token");
        if(token){
          this.evaluateUploadHeaders.Authorization = token;
        }
      }
      // 生成groupId的uuid
      this.evaluateUploadData.groupId = defaultUtils.getUuidString();
      // 初始化数据
      this.showEvaluateInfo = {
        evaluate_user_id : item.user_id,
        evaluate_message : '',
        file_group_id    : this.evaluateUploadData.groupId,
        business_type    : 3,
        business_id      : item.id,
        examine_status   : 0,
        examine_message  : '',
        // 附件列表
        fileUiList: [],
      }

    },
    evaluateUploadSuccess(res){
      // 上传成功的暂时不需要处理
    },
    async evaluateUploadRemove(uploadFile){
      // 获取上传附件的id
      if(uploadFile && uploadFile.response && uploadFile.response.content && uploadFile.response.content.id){
        let fileId = uploadFile.response.content.id;
        // 调用删除接口
        await nodeServer.filesInfo_deleteById({id: fileId});
      }
    },
    evaluateUploadExceed(){
      ElMessage.error("限制最多只能上传3张图片")
    },
    evaluateHandlePictureCardPreview(uploadFile){
      this.dialogImageVisible = true;
      this.dialogImageUrl = uploadFile.url;
    },
    closedEvaluateInfoFlag(){
      // 判断如果没有保存的，需要删除已经上传的照片
      if(!this.openEvaluateHasSave){
        for(let fileItem of this.showEvaluateInfo.fileUiList){
          this.evaluateUploadRemove(fileItem);
        }
      }
    },
    async saveEvaluateInfoBtn(){
      // 保存数据到服务器
      const myComponentInstance = this.$refs.ShowEvaluateInfoFormRef;
      if (!myComponentInstance) return
      await myComponentInstance.validate((valid, fields) => {
        if (valid) {
          this.saveEvaluateInfoToService();
        } else {
          ElMessage.error('请先完成信息填写');
        }
      })
    },
    async saveEvaluateInfoToService(){
      this.openEvaluateHasSave = true;

      let entityInfo = JSON.parse(JSON.stringify(this.showEvaluateInfo));
      // 处理数据(移除附件列表)
      delete entityInfo.fileUiList;

      //  调用接口插入
      let res = await nodeServerRelease.releaseEvaluateInfo_create(entityInfo);
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        ElMessage.success('评论成功');
        // 关闭弹窗
        this.openEvaluateInfoFlag = false;
      } else {
        if(res.message && res.message.length < 50){
          ElMessage.error(res.message);
        }else{
          if(res.message && res.message.length < 50){
            ElMessage.error(res.message);
          }else{
            ElMessage.error('保存失败，请联系小哥');
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.release-job-info{
  width: 100%;
  height: 100%;
  .info-content, .info-edit{
    width: 100%;
    height: 100%;
  }
  .main-navigation{
    display: flex;
    height: 60px;
    line-height: 60px;
    .main-search-box{
      height: 100%;
      width: 100%;
      display: flex;

      .query-item{
        margin-left: 32px;
        .query-item-label{
          text-align: right;
        }
        .query-item-input{
          margin-left: 12px;
          width: 120px;
        }
      }
      .btn-group{
        margin-left: 64px;
        line-height: 64px;
        .el-button{
          margin-right: 12px;
          margin-bottom: 6px;
        }
      }
    }
  }
  .main-show{
    height: calc(100% - 60px);
    width: 100%;
    display: flex;
    position: relative;
    .main-show-nodata{
      position: absolute;
      width: 100%;
      top: 200px;
      text-align: center;
      font-size: 18px;
    }

    .main-show-left{
      height: 640px;
      width: 440px;
      margin-right: 12px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 8px 16px 8px 32px;
      .left-item{
        border-radius: 16px;
        width: 100%;
        height: 160px;
        background: linear-gradient(to bottom, #f5f5f5, #ffffff);
        margin-bottom: 20px;
        padding-top: 16px;
        cursor: pointer;
        position: relative;
        box-shadow: 0 0 8px rgba(140, 140, 140, 0.4); /* 水平偏移 垂直偏移 模糊半径 颜色 */
        &:hover{
          border: 2px solid #e9e9e9;
          box-shadow: 0 0 8px rgba(140, 140, 140, 0.6); /* 水平偏移 垂直偏移 模糊半径 颜色 */
        }
        &.active{
          border: 2px solid #e1e1e1;
          box-shadow: 0 0 8px rgba(140, 140, 140, 0.8); /* 水平偏移 垂直偏移 模糊半径 颜色 */
        }
        .left-item-full-time{
          position: absolute;
          top: 20px;
          right: 20px;
          width: 60px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          border-radius: 4px;
          border: 2px solid #e1e1e1;
          font-size: 14px;
          color: #8c8c8c;
          box-shadow: 0 0 8px rgba(140, 140, 140, 0.4); /* 水平偏移 垂直偏移 模糊半径 颜色 */
        }
        .left-item-title{
          height: 36px;
          line-height: 36px;
          width: calc(100% - 120px);
          font-size: 18px;
          font-weight: bold;
          margin: 0 24px;
          white-space: nowrap; /* 确保文本不换行 */
          overflow: hidden; /* 隐藏溢出的内容 */
          text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
        }
        .left-item-row{
          height: 28px;
          line-height: 28px;
          font-size: 15px;
          margin: 0 24px;
          white-space: nowrap; /* 确保文本不换行 */
          overflow: hidden; /* 隐藏溢出的内容 */
          text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */

          i.iconfont{
            font-size: 16px;
            color: #bfbfbf;
            margin-right: 6px;
          }
          span{
            margin-right: 6px;
          }
        }
        .left-item-foot{
          position: absolute;
          bottom: 0px;
          width: 100%;
          height: 36px;
          background: linear-gradient(to bottom, #f0f0f0, #fafafa);
          display: flex;
          padding: 0 24px;
          line-height: 36px;
          border-radius: 0 0 12px 12px;
          font-size: 15px;
          i.iconfont{
            font-size: 16px;
            color: #bfbfbf;
            margin-right: 6px;
          }
          .left-item-foot-name{

          }
          .left-item-foot-address{
            flex: 1;
            text-align: right;
            margin-right: 12px;
          }
        }
      }
      .left-item-loading{
        width: 100%;
        height: 80px;
      }
    }
    .main-show-right{
      height: 640px;
      width: calc(100% - 480px);
      border-radius: 16px;
      background: linear-gradient(to bottom, #f5f5f5, #ffffff);
      box-shadow: 0 0 8px rgba(140, 140, 140, 0.4); /* 水平偏移 垂直偏移 模糊半径 颜色 */
      padding: 8px 32px;
      margin: 6px;

      .right-header{
        height: 140px;
        width: 100%;
        border-bottom: 1px solid #d1d1d1;
        position: relative;
        .right-header-full-time{
          position: absolute;
          top: 8px;
          right: 0px;
          width: 80px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          border-radius: 6px;
          border: 2px solid #e1e1e1;
          font-size: 16px;
          color: #8c8c8c;
          box-shadow: 0 0 8px rgba(140, 140, 140, 0.4); /* 水平偏移 垂直偏移 模糊半径 颜色 */
        }
        .right-header-evaluate{
          position: absolute;
          bottom: 12px;
          right: 0px;
          .right-header-evaluate-btn{
            border-radius: 4px;
          }
        }
        .right-header-title{
          height: 48px;
          line-height: 48px;
          width: calc(100% - 100px);
          font-size: 22px;
          font-weight: bold;
        }
        .right-header-row{
          line-height: 28px;
          height: 28px;
          font-size: 15px;
          //margin: 0 24px;
          white-space: nowrap; /* 确保文本不换行 */
          overflow: hidden; /* 隐藏溢出的内容 */
          text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
          span{
            margin-right: 6px;
          }
        }
        i.iconfont{
          font-size: 16px;
          color: #bfbfbf;
          margin-right: 6px;
        }
        .right-header-link{
          font-size: 15px;
          line-height: 28px;
          height: 28px;
          margin-bottom: 12px;
          display: flex;
          .right-header-link-item{
            margin-right: 24px;
          }
        }
      }
      .right-content{
        height: calc(100% - 160px);
        width: calc(100% - 12px);
        font-size: 15px;
        margin-left: 8px;
        overflow-y: auto;
        overflow-x: hidden;
        .right-content-titleinfo{
          height: 32px;
          line-height: 32px;
          font-size: 17px;
          font-weight: bold;
          margin: 12px 0;
        }
        .right-content-introduction{
          line-height: 32px;
          margin-left: 16px;
        }
        .right-content-evaluate-item{
          line-height: 32px;
          font-size: 13px;
          border-radius: 6px;
          color: #8c8c8c;
          box-shadow: 0 0 8px rgba(140, 140, 140, 0.4); /* 水平偏移 垂直偏移 模糊半径 颜色 */
          margin: 0 32px 16px 16px;
          padding: 4px 16px;
          .right-content-evaluate-item-images{
            display: flex;
            .el-image{
              width: 160px;
              height: 120px;
              margin-right: 24px;
              border-radius: 4px;
            }
          }
          .right-content-evaluate-item-footer{
            font-size: 12px;
            color: #bfbfbf;
          }
        }
        .right-content-evaluate-more{
          display: flex;
          align-items: center; /* 垂直居中对齐 */
          justify-content:center;
          cursor: pointer;
          box-shadow: 0 0 8px rgba(140, 140, 140, 0.4); /* 水平偏移 垂直偏移 模糊半径 颜色 */
          margin: 0 32px 16px 16px;
          border-radius: 6px;
          color: #8c8c8c;
          height: 32px;
          font-size: 13px;
          .el-icon{
            margin-right: 8px;
          }
          &:hover{
            background: linear-gradient(to bottom, #f0f0f0, #fafafa);
          }
        }
      }
    }
  }
}

//弹窗
.edit-info-dialog-content{
  padding: 0 20px 24px 20px;
  .info-group{
    .info-group-title{
      height: 24px;
      line-height: 22px;
      font-size: 14px;
      border-bottom: 1px solid #8c8c8c;
      margin-bottom: 16px;
    }
    .info-row{
      display: flex;
      .info-item{
        flex: 1;
        margin-right: 32px;
        .tag-input, .keyword-button-new-tag{
          width: 160px !important;
        }
        .el-tag{
          margin-right: 8px;
          margin-bottom: 4px;
        }
        .tip-text{
          font-size: 12px;
          margin-left: 12px;
          color: #8c8c8c;
        }
      }
    }
  }
  .btn-group{
    text-align: center;
  }
}
</style>
<style lang="scss" >
.edit-info-dialog{
  width: 800px !important;
  .el-dialog__body{
    padding: 0;
  }
}
</style>
