<template>
  <div class="manage-overview-info" >
    <div class="real-time-content">
      <div class="real-time-item">
        <div class="real-time-item-row">
          <div class="label">用户总数：</div>
          <div class="value">{{realTimeCount.user_sl}}</div>
        </div>
        <div class="real-time-item-row">
          <div class="label">本月新增：</div>
          <div class="value">{{realTimeCount.month_user_sl}}</div>
        </div>
        <div class="real-time-item-row">
          <div class="label">当天新增：</div>
          <div class="value">{{realTimeCount.today_user_sl}}</div>
        </div>
      </div>
      <div class="real-time-item">
        <div class="real-time-item-row">
          <div class="label">订单总数：</div>
          <div class="value value-color1">{{realTimeCount.order_sl}}</div>
        </div>
        <div class="real-time-item-row">
          <div class="label">本月新增：</div>
          <div class="value value-color1">{{realTimeCount.month_order_sl}}</div>
        </div>
        <div class="real-time-item-row">
          <div class="label">当天新增：</div>
          <div class="value value-color1">{{realTimeCount.today_order_sl}}</div>
        </div>
      </div>
      <div class="real-time-item">
        <div class="real-time-item-row">
          <div class="label">订单总额（元）：</div>
          <div class="value value-color1">{{realTimeCount.order_cost}}</div>
        </div>
        <div class="real-time-item-row">
          <div class="label">本月新增（元）：</div>
          <div class="value value-color1">{{realTimeCount.month_order_cost}}</div>
        </div>
        <div class="real-time-item-row">
          <div class="label">当天新增（元）：</div>
          <div class="value value-color1">{{realTimeCount.today_order_cost}}</div>
        </div>
      </div>
    </div>
    <div class="echart-content">
      <div class="echart-item">
        <div class="echart-div" id="echarts1"></div>
      </div>
      <div class="echart-item">
        <div class="echart-div" id="echarts2"></div>
      </div>
    </div>
  </div>

</template>

<script>

import nodeServer from '../net/nodeServer';
import {dateUtils} from '../utils/dateUtils';
import * as echarts from 'echarts';

let echarts1 = null;
let echarts2 = null;

export default {
  name: 'ManageOverviewInfo',
  components: {
  },
  data () {
    return {
      realTimeCount: {}
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
    if (echarts1)
    {
      echarts1.dispose();
      echarts1 = null;
    }
    if (echarts2)
    {
      echarts2.dispose();
      echarts2 = null;
    }
  },
  methods: {
    init () {
      this.getOverviewDataCount();
    },
    // 查询
    async getOverviewDataCount(){
      let res = await nodeServer.dataCount_getOverviewDataCount({});
      if(res.success && res.content){
        // 获取实时统计数据
        this.realTimeCount = res.content;

        // 获取趋势列表
        let rows = res.content.pageData.rows;
        rows.reverse();
        // 添加当天的数据
        rows.push({
          count_day: dateUtils.getTodayDateString(),
          daily_user_sl: res.content.today_user_sl,
          daily_order_sl: res.content.today_order_sl,
          daily_order_cost: res.content.today_order_cost
        })

        // 绘制echarts
        this.initEcharts1(rows);
        this.initEcharts2(rows);
      }
    },
    initEcharts1(rows){
      // 基于准备好的dom，初始化echarts实例
      echarts1 = echarts.init(document.getElementById('echarts1'));

      let xAxisData = [];
      let yAxis1Data = [];
      let yAxis2Data = [];
      for(let item of rows){
        xAxisData.push(item.count_day);
        yAxis1Data.push(item.daily_user_sl);
        yAxis2Data.push(item.daily_order_sl);
      }

      // 绘制图表
      echarts1.setOption({
        title: {
          text: '用户/订单每日趋势'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['用户数', '订单数']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '用户数',
            type: 'line',
            data: yAxis1Data
          },
          {
            name: '订单数',
            type: 'line',
            data: yAxis2Data
          }
        ]
      });
    },
    initEcharts2(rows){
      // 基于准备好的dom，初始化echarts实例
      echarts2 = echarts.init(document.getElementById('echarts2'));

      let xAxisData = [];
      let yAxis1Data = [];
      for(let item of rows){
        xAxisData.push(item.count_day);
        yAxis1Data.push(item.daily_order_cost);
      }

      // 绘制图表
      echarts2.setOption({
        title: {
          text: '订单金额每日趋势'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['订单金额']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '订单金额',
            type: 'line',
            data: yAxis1Data
          }
        ]
      });
    },

  }
}
</script>
<style lang="scss" scoped>
.manage-overview-info{
  width: 100%;
  height: 100%;
  .real-time-content{
    padding: 32px 64px 16px 64px;
    height: 240px;
    display: flex;
    .real-time-item{
      width: 32%;
      border-left: #d9d9d9 solid 1px;
      .real-time-item-row{
        font-size: 18px;
        text-align: center;
        line-height: 40px;
        margin-top: 16px;
        display: flex;
        .label{
          width: 180px;
          text-align: right;
          margin-right: 32px;
        }
        .value{
          font-size: 24px;
          font-weight: bold;
          color: #36cfc9;
        }
        .value-color1{
          color: #4096ff;
        }
      }
    }
    .real-time-item:first-child{
      border-left: none;
    }
  }
  .echart-content{
    margin-top: 32px;
    padding: 32px 64px 0px 64px;
    height: 320px;
    display: flex;
    .echart-item{
      width: 45%;
      margin-left: 24px;
      .echart-div{
        width: 100%;
        height: 320px;
      }
    }
  }
}
</style>
