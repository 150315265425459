import { createApp } from 'vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/rem';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/styles/index.scss'
import './assets/styles/reset.css'

import * as Icons from '@element-plus/icons-vue';

// 引入iconfont
import './assets/iconfont/iconfont.css'

const app = createApp(App)

app.use(store)
app.use(router)

// 注册所有图标
Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key]);
});

app.use(ElementPlus, {
  locale: zhCn,   //默认中文
})
app.mount('#app')

window.app = app
window.agLayer = null
