<template>
  <div class="manage-feedback-info" >
    <div class="table-header">
      <div class="btn-group">
        <el-button type="primary" @click="searchBtn">查询</el-button>
      </div>
    </div>

    <div class="table-content custom-overflow">
      <el-table :data="pageData.rows" stripe style="width: 100%" show-overflow-tooltip >
        <el-table-column prop="user_id" label="用户id"  width="80" />
        <el-table-column prop="user_name" label="昵称"   width="160"  />
        <el-table-column prop="feedback_content" label="反馈意见" />
        <el-table-column prop="create_time" label="创建时间"  width="160"  />
      </el-table>
      <div class="pagination">
        <div class="total">记录数: {{pageData.total}}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total" @current-change="currentChange" />
      </div>
    </div>
  </div>
</template>

<script>

import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import nodeServerRelease from '@/net/nodeServerRelease'

export default {
  name: 'ManageFeedbackInfo',
  components: {

  },
  data () {
    return {
      dictInfo: {},
      queryParams: {

      },

      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },

    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    async init () {
      //  初始化行政区划
      // await this.initdictInfo();

      await this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {

      }
    },
    async initdictInfo(){
      let res = await nodeServer.regionInfo_getDictInfo();
      if(res.success && res.content){
        this.dictInfo = res.content;
      }
    },
    // 查询
    async searchBtn(){
      let searchParams = {
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
      }
      let res = await nodeServer.feedbackInfo_getPage(searchParams);
      if(res.success && res.content){
        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
    getDictNameByDictCode(dictType, dictCode){
      if(this.dictInfo && this.dictInfo[dictType] && this.dictInfo[dictType][dictCode]){
        return this.dictInfo[dictType][dictCode];
      }
      return '';
    },
  }
}
</script>
<style lang="scss" scoped>
.manage-feedback-info{
  width: 100%;
  height: 100%;
  .table-header{
    height: 80px;
    line-height: 80px;
    display: flex;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .table-content{
    height: calc(100% - 80px);
    overflow-y: auto;
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }

    .success-row{
      color: #00c48f;
    }
    .warning-row{
      color: #f44336;
    }
    .cancel-row{
      color: #ff9f00;
    }
  }
}
</style>
<style lang="scss">
  .order-info-dialog{
    width: 1200px !important;
    .el-dialog__body{
      padding: 0;
    }
  }
</style>
